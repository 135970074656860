@import "support";

.document_pgBlk {
  display: block;
  margin-top: 25px;

  ul.summaryVblk {
    display: block;
    margin: 0px;
    padding: 0px;

    li.summaryVblk {
      display: inline-block;
      vertical-align: top;
      width: auto;
    }

    li.summaryVblk:nth-child(1) {
      width: 190px;
    }

    li.summaryVblk:nth-child(2) {
      width: 170px;
    }

    li.summaryVblk:nth-child(3) {
      width: 120px;
    }

    li.summaryVblk:nth-child(4) {
      width: 170px;
    }

    li.summaryVblk:nth-child(5) {
      width: 120px;
    }

    li.summaryVblk:last-child {
      width: calc(100% - 770px);
      width: -webkit-calc(100% - 770px);
    }

    @media ($smaller-than: $breakpoint-999) {

      li.summaryVblk:nth-child(1),
      li.summaryVblk:nth-child(2),
      li.summaryVblk:nth-child(3),
      li.summaryVblk:nth-child(4),
      li.summaryVblk:nth-child(5),
      li.summaryVblk:last-child {
        width: calc(100% / 2 - 4px);
        width: -webkit-calc(100%/2 - 4px);
        margin: 0 0 20px 0;
      }

      li.summaryVblk:nth-child(5),
      li.summaryVblk:last-child {
        margin: 0px;
      }
    }

    ::-webkit-input-placeholder {
      color: $color-lightblack;
    }

    :-moz-placeholder {
      color: $color-lightblack;
    }

    ::-moz-placeholder {
      color: $color-lightblack;
    }

    :-ms-input-placeholder {
      color: $color-lightblack;
    }

    select:required:invalid {
      color: $color-lightblack;
    }

    option {
      color: $color-lightblack;
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 90%;
      height: 24px;
      font-family: $font-agaramond;
      font-size: $fontsize-16;
      color: $color-blackbrown;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
      outline: 0;
      padding: 0 7px;
      background: $selectarrow_blackIcon_SVG;
      background-position: right center;
      background-color: $color-offwhitelight;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    select:hover {
      cursor: pointer;
      background: $selectarrow_blueIcon_SVG;
      background-position: right center;
      background-color: $color-white;
    }

    input[type="text"] {
      height: 25px;
      padding: 4px;
      border: 1px solid $color-blue-lighter;
      border-right: 0px;
      background-image: $bgTexure;
      font: $fontsize-14 $font-arial;
      color: $color-blackbrown;
      outline: 0;
      border-radius: 4px 0 0 4px;
    }

    .loanSearchTxtBox {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 50px);
      width: -webkit-calc(100% - 50px);
    }

    .iconsearch {
      display: inline-block;
      width: 25px;
      aspect-ratio: 1/1;
      border: 1px solid $color-blue-lighter;
      background: $search_blackIcon_SVG $color-offwhite;
      background-position: center center;
      background-size: 12px;
      margin-right: 25px;
      border-radius: 0 4px 4px 0;
    }

    .iconsearch:hover {
      cursor: pointer;
      background: $search_blueIcon_SVG $color-offwhite;
      background-position: center center;
      background-size: 12px;
    }

    .summaryHead,
    .summaryText {
      display: block;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-14;
      color: $color-black;
      margin-bottom: 3px;
    }

    .summaryText {
      font-family: $font-arial;
      color: $color-blackbrown;
      font-weight: normal;
      margin-top: 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .loanMoreButton {
      padding: 7px 13px;
      border: 0px solid $color-blue;
      border-radius: 2px;
      font-family: $font-agaramond;
      font-size: $fontsize-15;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
      border-radius: 50px;
    }

    .loanMoreButton:hover {
      cursor: pointer;
      background: $color-blue-dark;
    }

    li.noLoan {
      display: inline-block;
      vertical-align: top;
      width: auto;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-lightblack-dark;
      padding-top: 23px;
    }
  }

  /*View Table*/
  .viewISPTable_head,
  .viewISPTable {
    display: block;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: $color-offwhite;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-blue;
    }

    table {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin: 0px;
      padding: 0px;
      background-image: $bgTexure;

      td:nth-child(1) {
        width: 50px;
        padding-left: 15px;
      }

      td:nth-child(2) {
        width: auto;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
      }

      td:nth-child(3) {
        width: 140px;
        padding-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      td:nth-child(4) {
        width: 250px;
        padding-left: 10px;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // white-space: nowrap;
      }

      td:nth-child(5) {
        width: 60px;
        text-align: center;
      }

      td:nth-child(6) {
        width: 150px;
      }

      td:nth-child(7) {
        width: 170px;
      }

      td:nth-child(8) {
        width: 50px;
        text-align: right;
      }

      td:nth-child(9) {
        width: 50px;
        text-align: right;
      }

      td:last-child {
        width: 100px;
        text-align: right;
        padding-right: 25px;
      }

      td {
        border-bottom: 1px solid $color-lighterwhite;
        font-family: $font-arial;
        font-size: $fontsize-14;
        font-weight: bold;
        color: $color-black;
        height: 34px;
        line-height: 34px;
      }

      td a {
        color: $color-blackbrown;
        text-decoration: none;
      }
    }

    @media ($smaller-than: $breakpoint-1200) {

      td:nth-child(1),
      td:nth-child(5),
      td:nth-child(6),
      td:nth-child(7) {
        display: none;
      }

      td:nth-child(2) {
        padding-left: 15px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {

      td:nth-child(8),
      td:nth-child(9),
      td:nth-child(10) {
        display: none;
      }
    }
  }

  .viewISPTable {
    overflow-y: scroll;
    height: 355px;

    table {
      background-image: none;

      td:last-child {
        width: 85px;
      }

      td {
        vertical-align: top;
        font-weight: normal;
        padding: 7px 0;
        height: auto;
        line-height: 20px;
      }

      td a {
        color: $color-blue;
        text-decoration: none;
      }

      td a:hover {
        color: $color-blackbrown;
      }

      tr:last-child td {
        border-bottom: 0px;
      }
    }

    .approveDenyButton {
      padding: 5px;
      width: 70px;
      border: 0px solid $color-blue;
      border-radius: 3px;
      font-family: $font-agaramond;
      font-size: $fontsize-15;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
    }

    .approveDenyButton:hover {
      cursor: pointer;
      background: $color-blue-dark;
    }

    .approveDenyButton[disabled],
    .approveDenyButton[disabled]:hover,
    .approveDenyButton.disabled,
    .approveDenyButton.disabled:hover {
      background: $color-lightblack;
      padding: 5px 10px;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }
  }

  @-moz-document url-prefix() {
    .viewISPTable {
      height: 355px;
    }
  }

  @media ($smaller-than: $breakpoint-699) {
    .viewISPTable {
      height: 200px;
    }

    @-moz-document url-prefix() {
      .viewISPTable {
        height: 200px;
      }
    }
  }

  /*Approval Table*/
  .approvalTable_head,
  .approvalTable {
    display: block;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: $color-offwhite;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-blue;
    }

    table {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin: 0px;
      padding: 0px;
      background-image: $bgTexure;

      td:nth-child(1) {
        width: 50px;
        padding-left: 15px;
      }

      td:nth-child(2) {
        width: 30px;
      }

      td:nth-child(3) {
        width: 130px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      td:nth-child(4) {
        width: 140px;
        padding-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      td:nth-child(5) {
        padding-left: 10px;
      }

      td:nth-child(6) {
        width: 60px;
        text-align: center;
      }

      td:nth-child(7) {
        width: 150px;
      }

      td:nth-child(8) {
        width: 170px;
      }

      td:nth-child(9),
      td:nth-child(10) {
        width: 50px;
        text-align: right;
      }

      td:last-child {
        width: 135px;
        text-align: right;
        padding-right: 25px;
      }

      td {
        border-bottom: 1px solid $color-lighterwhite;
        font-family: $font-arial;
        font-size: $fontsize-14;
        font-weight: bold;
        color: $color-black;
        height: 34px;
        line-height: 34px;
      }

      td a {
        color: $color-blackbrown;
        text-decoration: none;
      }
    }

    @media ($smaller-than: $breakpoint-1200) {

      td:nth-child(2),
      td:nth-child(6),
      td:nth-child(7),
      td:nth-child(8) {
        display: none;
      }
    }

    @media ($smaller-than: $breakpoint-699) {

      td:nth-child(5),
      td:nth-child(9),
      td:nth-child(10) {
        display: none;
      }

      td:nth-child(4) {
        width: none;
      }
    }
  }

  .approvalTable {
    overflow-y: scroll;
    height: 250px;

    table {
      background-image: none;

      td:last-child {
        width: 70px;
      }

      td {
        vertical-align: top;
        font-weight: normal;
        padding: 7px 0;
        height: auto;
        line-height: 20px;
      }

      td a {
        color: $color-blue;
        text-decoration: none;
      }

      td a:hover {
        color: $color-blackbrown;
      }

      tr:last-child td {
        border-bottom: 0px;
      }

      td:last-child {
        width: 120px;
        padding-right: 8px;
        text-align: right;
      }
    }

    .approveDenyStatus {
      float: right;
      height: 22px;
      line-height: 23px;
      width: 70px;
      border-radius: 3px;
      font-family: $font-arial;
      font-size: $fontsize-13;
      color: $color-white;
      outline: none;
      background: $color-lighterblack;
      text-align: center;
    }
  }

  @-moz-document url-prefix() {
    .approvalTable {
      height: 250px;
    }
  }

  @media ($smaller-than: $breakpoint-699) {
    .approvalTable {
      height: 110px;
    }

    @-moz-document url-prefix() {
      .approvalTable {
        height: 110px;
      }
    }
  }

  .approvalButLabel {
    display: block;
    padding: 15px 20px;
    border-top: 1px solid $color-offblack;
    background-image: $bgTexure;

    .appDenyButton {
      padding: 0px 15px;
      height: 35px;
      border: 0px solid $color-blue;
      border-radius: 2px;
      font-family: $font-arial;
      font-size: $fontsize-15;
      font-weight: bold;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
      border-radius: 4px;
      margin-right: 8px;
    }

    .appDenyButton:hover {
      cursor: pointer;
      background: $color-blue-dark;
    }

    .appDenyButton[disabled],
    .appDenyButton[disabled]:hover,
    .ackBappDenyButtonutton.disabled,
    .appDenyButton.disabled:hover {
      background: $color-lightblack;
      padding: 0px 15px;
      height: 35px;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }
  }

  .ack_but_label {
    display: block;
    margin-top: 15px;

    .ackButton {
      padding: 0px 15px;
      height: 30px;
      border: 0px solid $color-blue;
      border-radius: 2px;
      font-family: $font-arial;
      font-size: $fontsize-15;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
      border-radius: 4px;
    }

    .ackButton:hover {
      cursor: pointer;
      background: $color-blue-dark;
    }

    .ackButton[disabled],
    .ackButton[disabled]:hover,
    .ackButton.disabled,
    .ackButton.disabled:hover {
      background: $color-lightblack;
      padding: 0px 15px;
      height: 30px;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }
  }

  .ackCheckLabel {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-14;
    color: $color-black;
  }

  .uploadDoc {
    display: block;

    .uploadDocHeadBlk {
      display: block;
      padding: 7px 15px 8px 15px;
      background: $color-blue-form-bg-dark;
      background-image: $bgTexure;
      border: 1px solid $color-lighterwhite;
      border-bottom: none;

      .uploadHeading {
        display: inline-block;
        vertical-align: top;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-15;
        color: $color-blackbrown;
        margin: 10px 8px 0px 0px;
      }

      .selectDocType {
        display: inline-block;
        vertical-align: top;
        width: 200px;

        select {
          padding-left: 10px;
        }
      }
    }

    ul.uploadIIIcol {
      display: block;

      li.uploadIIIcol {
        display: inline-block;
        vertical-align: top;
        margin-right: 15px;
      }

      li.uploadIIIcol:first-child {
        width: 250px;
      }

      li.uploadIIIcol:nth-child(2) {
        width: calc(100% - 630px);
        width: -webkit-calc(100% - 620px);
      }

      li.uploadIIIcol:nth-child(3) {
        width: 340px;
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-999) {

        li.uploadIIIcol:first-child,
        li.uploadIIIcol:nth-child(2),
        li.uploadIIIcol:nth-child(3) {
          display: block;
          width: 100%;
          margin-right: 0px;
          margin-bottom: 15px;
        }
      }
    }

    .uploadSubHead {
      display: block;
      margin-top: 20px;
      padding: 8px 15px 9px 15px;
      background: $color-offwhite;
      background-image: $bgTexure;
      border: 1px solid $color-lighterwhite;
      border-bottom: none;
      font-family: $font-arial;
      font-weight: bold;
      font-size: $fontsize-15;
      color: $color-blackbrown;
    }
  }

  /*View Uploaded Table*/
  .viewDocTable_head,
  .viewDocTable {
    display: block;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: $color-offwhite;
    }

    ::-webkit-scrollbar-thumb {
      background: $color-blue;
    }

    table {
      table-layout: fixed;
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      margin: 0px;
      padding: 0px;
      background-image: $bgTexure;

      td:nth-child(1) {
        width: 250px;
        padding-left: 15px;
      }

      td:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      td:nth-child(3) {
        width: 150px;
      }

      td:last-child {
        width: 100px;
        text-align: right;
        padding-right: 15px;
      }

      td {
        border-bottom: 1px solid $color-lighterwhite;
        font-family: $font-arial;
        font-size: $fontsize-14;
        font-weight: bold;
        color: $color-black;
        height: 30px;
        line-height: 30px;
      }

      td a {
        color: $color-blackbrown;
        text-decoration: none;
      }

      @media ($smaller-than: $breakpoint-699) {
        td:nth-child(1) {
          width: 150px;
        }

        td:nth-child(3) {
          display: none;
        }
      }
    }
  }

  .viewDocTable {
    overflow-y: auto;
    height: 108px;

    table {
      background-image: none;

      td {
        vertical-align: top;
        font-weight: normal;
        padding: 7px 0;
        height: auto;
        line-height: 20px;
      }

      td a {
        color: $color-blue;
        text-decoration: none;
      }

      td a:hover {
        color: $color-blackbrown;
      }

      tr:last-child td {
        border-bottom: 0px;
      }
    }

    .viewDoc_btn {
      display: inline-block;
      vertical-align: middle;
      padding: 0px 10px;
      height: 22px;
      margin: -3px 5px 0px 0px;
      border: 0px solid $color-blue;
      border-radius: 5px;
      font-family: $font-arial;
      font-size: $fontsize-13;
      color: $color-white;
      outline: none;
      background: $color-blue;
      text-align: center;
    }

    .viewDoc_btn:hover {
      cursor: pointer;
    }
  }

  @-moz-document url-prefix() {
    .viewDocTable {
      height: 108px;
    }
  }
}