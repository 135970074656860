@import "support";
ul.loPgStructure {
  display: block;
  margin-top: 35px;

  li.loPgStructure {
    display: block;
    margin-bottom: 30px;
  }

  li.loPgStructure:last-child {
    padding-top: 15px;
  }

  @media ($smaller-than: $breakpoint-1200) {
    li.loPgStructure {
      display: inline-block;
      vertical-align: top;
      margin: 0px;
    }

    li.loPgStructure:first-child {
      display: none;
    }

    li.loPgStructure:last-child {
      width: 100%;
    }
  }
}

ul.loIconNavi {
  display: block;

  li.loIconNavi {
    display: inline;
  }

  li.loIconNavi {
    margin-right: 5px;
  }

  li.loIconNavi:last-child {
    margin-right: 0px;
  }

  li.loIconNavi a {
    display: inline-block;
    width: calc(100% / 9 - 5px);
    width: -webkit-calc(100% / 9 - 5px);
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-19;
    color: $color-lighterblack;
    text-align: center;
    text-decoration: none;
    padding-bottom: 5px;
    transition-property: color;
    transition-duration: 0.5s;
    -webkit-transition: 0s;
    transition: 0.5s;
    transition-delay: 0.1s;
  }

  li.loIconNavi a:hover {
    color: $color-red;
    cursor: pointer;
  }

  li.loIconNavi a.current {
    position: relative;
    color: $color-black;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  }

  li.loIconNavi a.current:after {
    content: " ";
    position: absolute;
    height: 0;
    width: 0;
    border: 15px solid transparent;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top-color: $color-red;
    top: 97px;
    left: calc(50% - 25px);
    left: -webkit-calc(50% - 25px);
  }

  .lo_icon1,
  .lo_icon2,
  .lo_icon3,
  .lo_icon4,
  .lo_icon5,
  .lo_icon6,
  .lo_icon7,
  .lo_icon8,
  .lo_icon9 {
    width: 50px;
    height: 65px;
    margin: 0px auto 5px auto;
  }

  .lo_icon1 {
    background: $lo_30yr_SVG;
    background-size: 50px;
    background-position: center center;
  }

  .lo_icon2 {
    background: $lo_usda_SVG;
    width: 60px;
    background-size: 60px;
    background-position: center center;
  }

  .lo_icon3 {
    background: $lo_va_SVG;
    background-size: 50px;
    background-position: center center;
  }

  .lo_icon4 {
    background: $lo_fha_SVG;
    background-size: 44px;
    background-position: center center;
  }

  .lo_icon5 {
    background: $lo_reverse_SVG;
    background-size: 40px;
    background-position: center center;
  }

  .lo_icon6 {
    background: $lo_jumbo_SVG;
    width: 60px;
    background-size: 60px;
    background-position: center center;
  }

  .lo_icon7 {
    background: $lo_renovation_SVG;
    background-size: 50px;
    background-position: center center;
  }

  .lo_icon8 {
    background: $lo_nonqm_SVG;
    width: 50px;
    background-size: 50px;
    background-position: center center;
  }

  .lo_icon9 {
    background: $lo_conventional_SVG;
    background-size: 50px;
    background-position: center center;
  }

  @media ($smaller-than: $breakpoint-999) {
    li.loIconNavi a.current {
      box-shadow: none;
      background-image: none;
    }

    li.loIconNavi a.current:after {
      display: none;
    }

    li.loIconNavi {
      margin-bottom: -12px;
    }

    li.loIconNavi a {
      display: block;
      width: 100%;
      height: auto;
      font-size: $fontsize-16;
      text-align: left;
      border-top: 1px dashed $color-red;
      // padding: 8px 0px 8px 5px;
      padding: 0px;
      height: 23px;
      line-height: 23px;
    }

    li.loIconNavi a.current:after {
      border: 15px solid transparent;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left-color: $color-red;
      left: 195px;
      top: calc(50% - 8px);
      top: -webkit-calc(50% - 8px);
    }

    .lo_icon1,
    .lo_icon2,
    .lo_icon3,
    .lo_icon4,
    .lo_icon5,
    .lo_icon6,
    .lo_icon7,
    .lo_icon8,
    .lo_icon9 {
      display: inline-block;
      vertical-align: middle;
      width: 45px;
      height: 35px;
      background-size: 25px;
      background-position: left 5px center;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    li.loIconNavi a {
      display: block;
      width: 100%;
      height: auto;
      font-size: $fontsize-15;
      text-align: left;
      border-top: 1px dashed $color-red;
      // padding: 10px 0px 10px 5px;
      padding: 0px;
      height: 20px;
      line-height: 35px;
    }

    li.loIconNavi a.current:after {
      border: 8px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left-color: $color-red;
      left: 135px;
      top: calc(50% - 5px);
      top: -webkit-calc(50% - 5px);
    }

    .lo_icon1,
    .lo_icon2,
    .lo_icon3,
    .lo_icon4,
    .lo_icon5,
    .lo_icon6,
    .lo_icon7,
    .lo_icon8,
    .lo_icon9 {
      display: none;
    }
  }
}

ul.loPointsBlk {
  display: block;

  li.loPointsBlk {
    display: inline-block;
    vertical-align: top;
    margin: 0px;
  }

  li.loPointsBlk:first-child {
    width: 350px;
    padding-top: 10px;
  }

  li.loPointsBlk:last-child {
    width: calc(100% - 370px);
    width: -webkit-calc(100% - 370px);
    margin-left: 20px;
  }

  @media ($smaller-than: $breakpoint-999) {
    li.loPointsBlk:first-child {
      width: 250px;
    }

    li.loPointsBlk:last-child {
      width: calc(100% - 260px);
      width: -webkit-calc(100% - 260px);
      margin-left: 10px;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    li.loPointsBlk:first-child {
      display: none;
    }

    li.loPointsBlk:last-child {
      width: 100%;
      margin-left: 0px;
    }
  }

  ul.loDescPoints {
    display: block;

    li.loDescPoints {
      display: block;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-black;
      margin-bottom: 25px;
    }

    li.loDescPoints::before {
      content: "\25BA";
      margin-right: 10px;
      color: $color-red;
    }

    .loButton {
      margin: 10px 0px 20px 0px;
      padding: 15px 20px;
      border: none;
      border-radius: 2px;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-18;
      color: $color-white;
      text-transform: uppercase;
      background: $color-red;
      background-image: $bgTexure;
      outline: none;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
    }

    .loButton:hover {
      background: $color-red-dark;
      cursor: pointer;
    }
  }
}
