@import "support";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Use the variables */
body {
  font-family: $font-agaramond;
  font-size: $fontsize-16;
  width: 100%;
  height: 100%;
  color: $color-black;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body > #root {
  width: 100%;
  height: 100%;
}

#homeVideo {
  margin: 0px;
  width: 100%;
  height: 100%;
  background: $color-black;

  .videoContainer {
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
    opacity: 0.5;
  }

  .videoContainer video {
    display: block;
    top: 0%;
    left: 0%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transition: 1s opacity;
  }

  .videoContainer video::-webkit-media-controls {
    display: none !important;
  }

  .video1-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;
    z-index: -100;
    // background: transparent url(../img/smallscreen.jpg) no-repeat;
    background-size: cover;
  }

  .fallback_smallscreen {
    display: none;
    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    bottom: 0;
    right: 0;
    opacity: 0.35;
    background: transparent url(../img/smallscreen.jpg) no-repeat;
    background-size: cover;
  }

  @media ($smaller-than: $breakpoint-499) {
    .videoContainer {
      display: none;
    }
    .videoContainer video {
      display: none;
    }
    .fallback_smallscreen {
      display: block;
    }
  }

  .videoContent_blk {
    position: absolute;
    top: 22%;
    width: 100%;
    z-index: 15;

    ul.homeText_Quote_blk {
      display: block;

      li.homeText {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 400px);
        width: -webkit-calc(100% - 400px);
        margin-right: 29px;

        .homeMainTxtBlock {
          display: block;

          h1 {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-50;
            color: $color-white;
            margin: 0px 0px 30px 0px;
            opacity: 0.9;

            .mainTxt_line {
              display: block;
              margin-bottom: 4px;
            }
          }

          @media ($smaller-than: $breakpoint-699) {
            h1 {
              font-size: $fontsize-34;
            }
          }

          @media ($smaller-than: $breakpoint-599) {
            h1 {
              font-size: $fontsize-32;
              margin-bottom: 25px;
            }
          }

          @media ($smaller-than: $breakpoint-499) {
            h1 {
              font-size: $fontsize-23;
              margin-bottom: 20px;
            }
          }
        }

        .secondaryTxt {
          display: block;
          font-family: $font-agaramond;
          font-size: $fontsize-22;
          color: $color-white;
          margin: 0px 0px 55px 0px;
          opacity: 0.9;
          width: 75%;
          line-height: 30px;
        }

        @media ($smaller-than: $breakpoint-899) {
          .secondaryTxt {
            width: 90%;
          }
        }

        @media ($smaller-than: $breakpoint-599) {
          .secondaryTxt {
            font-size: $fontsize-20;
            width: 100%;
          }
        }

        @media ($smaller-than: $breakpoint-499) {
          .secondaryTxt {
            font-size: $fontsize-18;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }

        .homeButLabel {
          display: block;

          .but_preQuaify {
            display: inline-block;
            vertical-align: top;
            padding: 18px 42px;
            border-radius: 10px;
            border: none;
            font-family: $font-agaramondB;
            font-size: $fontsize-24;
            color: $color-blackbrown;
            background: $bgTransparent_85;
            opacity: 0.85;
            outline: none;
            transition: background 0.5s;
            -webkit-transition: background 0.5s;
          }

          .but_preQuaify:hover {
            opacity: 1;
            cursor: pointer;
          }

          @media ($smaller-than: $breakpoint-699) {
            .but_preQuaify {
              padding: 10px 25px;
              height: auto;
              font-size: $fontsize-16;
            }
          }
        }
      }

      li.requestQuote {
        display: inline-block;
        vertical-align: top;
        width: 370px;
        background: $bgTransparent_15;
        padding: 30px 45px 35px 45px;

        .requestHead,
        .requestSub {
          display: block;
          text-align: center;
          font-family: $font-agaramondB;
          font-size: $fontsize-28;
          color: $color-white;
          text-transform: uppercase;
          letter-spacing: 1px;
          opacity: 0.85;
        }

        .requestSub {
          font-size: $fontsize-14;
          opacity: 0.5;
          margin-bottom: 12px;
        }

        ::-webkit-input-placeholder {
          color: $color-blackbrown-light;
        }

        :-moz-placeholder {
          color: $color-black;
        }

        ::-moz-placeholder {
          color: $color-black;
        }

        :-ms-input-placeholder {
          color: $color-blackbrown-light;
        }

        select:required:invalid {
          color: $color-blackbrown-light;
        }

        option,
        option:hover {
          background-color: $color-lightblack;
        }

        input[type="text"] {
          width: 100%;
          font-family: $font-agaramondB;
          font-size: $fontsize-16;
          color: $color-black;
          padding: 0px 15px;
          height: 40px;
          border: 0px;
          border-radius: 7px;
          background: $bgTransparent_80;
          outline: 0;
          margin-bottom: 9px;
        }

        select {
          width: 100%;
          font-family: $font-agaramondB;
          font-size: $fontsize-16;
          color: $color-black;
          padding: 0px 15px;
          height: 40px;
          border: 0px;
          border-radius: 7px;
          background: $bgTransparent_80;
          margin-bottom: 9px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          appearance: none;
          outline: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
        }

        select:hover {
          cursor: pointer;
          background: $bgTransparent_80;
        }

        select option {
          background: $bgTransparent_80;
        }

        .requestButton {
          width: 100%;
          padding: 0 10px;
          height: 45px;
          border: 0px;
          border-radius: 7px;
          font: $fontsize-19 $font-agaramondB;
          color: $color-white;
          text-transform: uppercase;
          outline: none;
          background: $color-orange;
          text-align: center;
        }

        .requestButton:hover {
          background: $color-red;
          cursor: pointer;
        }
      }

      @media ($smaller-than: $breakpoint-1023) {
        li.homeText {
          display: block;
          width: 100%;
          padding-left: 10px;
        }

        li.requestQuote {
          display: none;
        }
      }
    }
  }

  @media ($smaller-than: $breakpoint-1200) {
    .videoContent_blk {
      top: 15%;
    }
  }
}

#home_bottom_blk {
  display: block;
  visibility: visible;
  width: 100%;
  height: 115px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 19;
  background: $bgTransparent_80;

  ul.home_IV_col {
    display: block;
    margin: 0px;
    padding: 0px;

    li.home_IV_col {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 4);
      width: -webkit-calc(100%/4);
      padding: 30px 0px 15px 0px;
      transition: background 0.8s;
      -webkit-transition: background 0.8s;
    }

    @media ($smaller-than: $breakpoint-1023) {
      li.home_IV_col {
        width: calc(100% / 2);
        width: -webkit-calc(100%/2);
        padding: 20px 0px 10px 0px;
      }
    }

    ul.bottomIconText_blk {
      display: block;

      li.bottomIcon_blk {
        display: inline-block;
        vertical-align: top;
        width: 60px;
      }

      li.bottomText_blk {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 65px);
        width: -webkit-calc(100% - 65px);
      }

      .bottomIcon1,
      .bottomIcon2,
      .bottomIcon3,
      .bottomIcon4 {
        width: 60px;
        height: 70px;
      }

      .bottomIcon1 {
        background: $lowRateIcon_SVG;
        background-size: 55px;
        background-position: left top;
      }

      .bottomIcon2 {
        background: $creditAmountIcon_SVG;
        background-size: 55px;
        background-position: left top;
      }

      .bottomIcon3 {
        background: $easyProcessIcon_SVG;
        background-size: 50px;
        background-position: left top;
      }

      .bottomIcon4 {
        background: $loanTermIcon_SVG;
        background-size: 55px;
        background-position: left top;
      }

      .bottom_blk_desc {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-13;
        color: $color-blue-dark;
        text-transform: uppercase;
        margin: 3px 0 8px 0;
        letter-spacing: 2px;
      }

      .bottom_blk_duration {
        display: inline-block;
        vertical-align: top;
        margin-top: 3px;
        font-family: $font-agaramond;
        font-size: $fontsize-30;
        color: $color-blue-bottomlight;
        text-transform: lowercase;
        margin-right: 3px;
        letter-spacing: 2px;
      }

      .bottom_blk_number,
      .bottom_blk_sign {
        display: inline-block;
        vertical-align: top;
        font-family: $font-agaramondB;
        font-size: $fontsize-38;
        color: $color-blue-darker;
      }

      @media ($smaller-than: $breakpoint-699) {
        li.bottomIcon_blk {
          width: 50px;
        }

        li.bottomText_blk {
          width: calc(100% - 55px);
          width: -webkit-calc(100% - 55px);
        }

        .bottomIcon1,
        .bottomIcon2,
        .bottomIcon3,
        .bottomIcon4 {
          width: 40px;
          height: 50px;
          background-size: 40px;
        }

        .bottom_blk_desc {
          font-size: $fontsize-12;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        .bottom_blk_duration {
          font-size: $fontsize-26;
          letter-spacing: normal;
          margin-right: 7px;
        }

        .bottom_blk_number,
        .bottom_blk_sign {
          font-size: $fontsize-30;
        }
      }

      @media ($smaller-than: $breakpoint-499) {
        li.bottomIcon_blk {
          width: 35px;
        }

        li.bottomText_blk {
          width: calc(100% - 40px);
          width: -webkit-calc(100% - 40px);
        }

        .bottomIcon1,
        .bottomIcon2,
        .bottomIcon3,
        .bottomIcon4 {
          width: 30px;
          height: 40px;
          background-size: 30px;
        }
        .bottom_blk_duration {
          font-size: $fontsize-20;
        }
      }
    }
  }
}

/*Bigger than 249px */
@media ($bigger-than: $breakpoint-249) {
  #home_bottom_blk {
    top: auto;
    bottom: 0px;
    position: absolute;
  }
}

/*Smaller than 1200px */
@media ($smaller-than: $breakpoint-1200) {
  #home_bottom_blk {
    height: auto;
  }
}

@media screen and (max-height: $breakpoint-499) {
  #home_bottom_blk {
    display: none;
  }
}

/*ABOUT US*/
ul.aboutContainer {
  display: block;
  margin: 100px 0px;

  li.aboutContainer {
    display: inline-block;
    vertical-align: top;
  }

  li.aboutContainer {
    width: 565px;
  }

  li.aboutContainer:last-child {
    width: calc(100% - 565px);
    width: -webkit-calc(100% - 565px);
    padding-left: 60px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.aboutContainer {
      width: 400px;
    }

    li.aboutContainer:last-child {
      width: calc(100% - 400px);
      width: -webkit-calc(100% - 400px);
      padding-left: 30px;
    }
  }

  @media ($smaller-than: $breakpoint-699) {
    li.aboutContainer,
    li.aboutContainer:last-child {
      display: block;
      width: 100%;
      padding: 0px;
    }
  }

  .aboutImgBlk {
    display: block;
    width: 100%;

    .aboutImage {
      width: 465px;
      height: 650px;
      background-image: url("../img/about.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    @media ($smaller-than: $breakpoint-1023) {
      .aboutImage {
        width: 300px;
        height: 450px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      .aboutImage {
        width: 85%;
        height: 450px;
      }
    }

    .expBlk {
      float: right;
      width: 200px;
      aspect-ratio: 1/1;
      background: $color-white;
      border: 1px solid $color-red-lightest;
      transform: translate(0%, 100%);
      padding: 12px;

      .expBlkContent {
        width: 100%;
        aspect-ratio: 1/1;
        background: $color-red;

        .txtExpNumber {
          display: block;
          text-align: center;
          padding-top: 30px;
          font-family: $font-agaramondB;
          font-size: $fontsize-80;
          color: $color-white;

          .symbolFont {
            font-size: $fontsize-6;
          }
        }

        .txtExp {
          display: block;
          text-align: center;
          font-family: $font-agaramondB;
          font-size: $fontsize-16;
          color: $color-white;
          text-transform: uppercase;
          margin-top: -15px;
        }

        @media ($smaller-than: $breakpoint-1023) {
          .txtExpNumber {
            padding-top: 20px;
            font-size: $fontsize-48;
          }

          .txtExp {
            font-size: $fontsize-14;
            margin-top: -5px;
          }
        }
      }
    }

    @media ($smaller-than: $breakpoint-1023) {
      .expBlk {
        width: 150px;
        aspect-ratio: 1/1;
      }
    }
  }

  .aboutContentBlk {
    display: block;

    .abt_headTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-red;
      text-transform: uppercase;
      margin: 25px 0 15px 0;
    }

    .abt_heroTxt {
      display: block;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-40;
      color: $color-blue-darker;
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    .abt_pointHeadTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-red;
      margin-bottom: 5px;
    }

    .abt_pointTxt {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-17;
      color: $color-blackbrown;
      margin-bottom: 24px;
    }

    @media ($smaller-than: $breakpoint-1023) {
      .abt_headTxt {
        font-size: $fontsize-18;
        margin: 0px 0 10px 0px;
      }

      .abt_heroTxt {
        font-size: $fontsize-24;
        margin-bottom: 20px;
      }

      .abt_pointHeadTxt {
        font-size: $fontsize-17;
        margin-bottom: 3px;
      }

      .abt_pointTxt {
        font-size: $fontsize-16;
        margin-bottom: 20px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      .abt_headTxt {
        margin: 30px 0 5px 0px;
      }
    }

    ul.abt_uspPoint {
      display: block;
      margin-top: 30px;

      li.abt_uspPoint {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        width: calc(100% / 2);
        width: -webkit-width (100% /2);
        font-family: $font-agaramondB;
        font-size: $fontsize-16;
        color: $color-blackbrown;
        margin-bottom: 10px;
      }

      li.abt_uspPoint::before {
        content: "\002b9a";
        margin-right: 10px;
        color: $color-blackbrown-light;
        font-size: $fontsize-20;
      }

      @media ($smaller-than: $breakpoint-1023) {
        li.abt_uspPoint {
          font-size: $fontsize-14;
        }
      }
    }

    .abt_button {
      border: 2px solid $color-red-lightest;
      padding: 24px 46px;
      font-family: $font-agaramondB;
      font-size: $fontsize-16;
      color: $color-blue-darker;
      text-transform: uppercase;
      margin-top: 23px;
      background: transparent;
      outline: none;
      transition-property: "background";
      transition-duration: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }

    .abt_button:hover {
      cursor: pointer;
      background: $color-blackbrown;
      border-color: $color-blackbrown;
      color: $color-white;
    }

    @media ($smaller-than: $breakpoint-999) {
      .abt_button {
        padding: 10px 22px;
        font-size: $fontsize-15;
      }
    }
  }
}

@media ($smaller-than: $breakpoint-1200) {
  ul.aboutContainer {
    margin: 60px 0px 50px 0px;
  }
}

/*Why i3Lending*/
.whyi3Blk {
  display: block;
  border-top: 1px solid $color-offblack-light;
  border-bottom: 1px solid $color-offblack-light;
  padding: 70px 0px;
  background: $color-offwhitelight;
  background-image: $bgTexure;

  .whyi3_blkHeadTxt {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-40;
    color: $color-blue-darker;
    text-align: center;
    margin-bottom: 15px;
  }

  .whyi3_descTxt {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-18;
    color: $color-blackbrown;
    margin-bottom: 50px;
    text-align: center;
    padding: 0 200px;
  }

  @media ($smaller-than: $breakpoint-999) {
    .whyi3_blkHeadTxt {
      font-size: $fontsize-30;
    }

    .whyi3_descTxt {
      font-size: $fontsize-16;
      padding: 0 50px;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    .whyi3_descTxt {
      padding: 0px;
      margin-bottom: 30px;
    }
  }

  ul.whyi3PointBlk {
    display: block;
    width: 1000px;
    margin: 0px auto 50px auto;

    li.whyi3PointBlk {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 5);
      width: -webkit-calc(100%/5);
      height: 100px;
      text-align: center;
    }

    .whyi3_icon1,
    .whyi3_icon2,
    .whyi3_icon3,
    .whyi3_icon4,
    .whyi3_icon5 {
      width: 70px;
      height: 70px;
      margin: 0px auto 25px auto;
    }

    .whyi3_icon1 {
      background: $foundationYearIcon_SVG;
      background-size: 70px;
      background-position: center center;
    }

    .whyi3_icon2 {
      background: $happyCustomerIcon_SVG;
      background-size: 70px;
      background-position: center center;
    }

    .whyi3_icon3 {
      background: $loanIssuedIcon_SVG;
      background-size: 70px;
      background-position: center center;
    }

    .whyi3_icon4 {
      background: $loanOfficerIcon_SVG;
      background-size: 70px;
      background-position: center center;
    }

    .whyi3_icon5 {
      background: $partnerIcon_SVG;
      background-size: 70px;
      background-position: center center;
    }

    .whyi3_blockHead,
    .whyi3_blockDesc {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-30;
      color: $color-blackbrown;
      text-align: center;
    }

    .whyi3_blockDesc {
      font-size: $fontsize-16;
      color: $color-lightblack;
    }

    @media ($smaller-than: $breakpoint-799) {
      .whyi3_icon1,
      .whyi3_icon2,
      .whyi3_icon3,
      .whyi3_icon4,
      .whyi3_icon5 {
        width: 55px;
        aspect-ratio: 1/1;
        background-size: 55px;
      }

      .whyi3_blockHead {
        font-size: $fontsize-28;
      }

      .whyi3_blockDesc {
        font-size: $fontsize-14;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      li.whyi3PointBlk {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 50px;
      }

      .whyi3_icon1,
      .whyi3_icon2,
      .whyi3_icon3,
      .whyi3_icon4,
      .whyi3_icon5 {
        width: 90px;
        height: 90px;
        background-size: 70px;
        margin: 0px auto 5px auto;
      }

      .whyi3_blockHead {
        font-size: $fontsize-32;
      }

      .whyi3_blockDesc {
        font-size: $fontsize-18;
      }
    }
  }

  @media ($smaller-than: $breakpoint-999) {
    ul.whyi3PointBlk {
      width: 100%;
    }
  }
}

@media ($smaller-than: $breakpoint-999) {
  .whyi3Blk {
    padding: 50px 0px 20px 0px;
  }
}

/*Loan Options*/
.LoanOptionBlk {
  display: block;
  padding: 70px 0px 70px 0px;

  .lo_blkHead,
  .lo_blkTxtDesc {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-40;
    color: $color-blue-darker;
    margin-bottom: 5px;
    text-align: center;
  }

  .lo_blkTxtDesc {
    font-size: $fontsize-18;
    color: $color-lightblack;
    margin-bottom: 50px;
    font-weight: normal;
  }

  @media ($smaller-than: $breakpoint-999) {
    .lo_blkHead {
      font-size: $fontsize-30;
    }

    .lo_blkTxtDesc {
      font-size: $fontsize-16;
      padding: 0 30px;
      margin-bottom: 40px;
    }
  }

  ul.loPointsDescBlk {
    display: block;

    li.loPointsDescBlk {
      display: block;
      margin-bottom: 10px;
    }

    @media ($smaller-than: $breakpoint-999) {
      li.loPointsDescBlk {
        display: inline-block;
        vertical-align: top;
        margin: 0px;
      }

      li.loPointsDescBlk:first-child {
        width: 160px;
      }

      li.loPointsDescBlk:last-child {
        width: calc(100% - 180px);
        width: -webkit-calc(100% - 180px);
        margin-left: 20px;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      li.loPointsDescBlk:first-child {
        width: 100px;
      }

      li.loPointsDescBlk:last-child {
        width: calc(100% - 110px);
        width: -webkit-calc(100% - 110px);
        margin-left: 10px;
      }
    }
  }

  ul.loDescBlk {
    display: block;
    margin-top: 15px;

    li.loDescBlk {
      display: inline-block;
      vertical-align: top;
    }

    li.loDescBlk:first-child {
      width: 375px;
    }

    li.loDescBlk:last-child {
      width: calc(100% - 375px);
      width: -webkit-calc(100% - 375px);
      background: url(../img/lo_bg.jpg) no-repeat;
      background-size: cover;
      height: 600px;
    }

    @media ($smaller-than: $breakpoint-999) {
      li.loDescBlk,
      li.loDescBlk:first-child {
        display: block;
        width: 100%;
      }

      li.loDescBlk:last-child {
        display: none;
      }
    }

    .loContentBox {
      position: absolute;
      width: 600px;
      transform: translate(0%, 10%);
      background: $color-white;
      background-image: $bgTexure;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.35);
      padding: 50px 60px;

      .loDescHead {
        display: block;
        font-family: $font-times;
        font-weight: bold;
        font-size: $fontsize-34;
        color: $color-blue-darker;
        margin-bottom: 35px;
      }

      @media ($smaller-than: $breakpoint-999) {
        .loDescHead {
          font-size: $fontsize-28;
        }
      }

      @media ($smaller-than: $breakpoint-599) {
        .loDescHead {
          font-size: $fontsize-20;
          margin-bottom: 20px;
        }
      }

      ul.loDescPoints {
        display: block;

        li.loDescPoints {
          display: block;
          font-family: $font-times;
          font-weight: bold;
          font-size: $fontsize-24;
          color: $color-lightblack;
          margin-bottom: 30px;
        }

        li.loDescPoints:last-child {
          margin-bottom: 50px;
        }

        li.loDescPoints::before {
          content: "\25BA";
          margin-right: 15px;
          color: $color-red;
        }

        @media ($smaller-than: $breakpoint-999) {
          li.loDescPoints {
            font-size: $fontsize-18;
            margin-bottom: 25px;
          }
        }

        @media ($smaller-than: $breakpoint-599) {
          li.loDescPoints {
            font-size: $fontsize-15;
            margin-bottom: 15px;
          }

          li.loDescPoints:last-child {
            margin-bottom: 30px;
          }

          li.loDescPoints::before {
            margin-right: 5px;
            font-size: $fontsize-14;
          }
        }
      }

      ul.loButtonLabel {
        display: block;

        li.loButtonLabel,
        li.loGetQuote {
          display: inline-block;
          vertical-align: top;
          width: calc(100% / 2);
          width: -webkit-calc(100% / 2);
        }

        li.loGetQuote {
          text-align: right;
          padding-top: 10px;
          text-decoration: none;
        }

        li.loGetQuote .getQuotelink {
          font-family: $font-agaramondB;
          font-size: $fontsize-20;
          color: $color-lightblack;
        }

        li.loGetQuote .getQuotelink:hover {
          color: $color-blackbrown-lighter;
        }

        li.loGetQuote .getQuotelink::after {
          content: "\0000bb";
          margin-left: 10px;
          font-size: $fontsize-30;
          color: $color-red;
        }

        .loButton {
          padding: 20px 40px;
          border: none;
          border-radius: 2px;
          font-family: $font-times;
          font-weight: bold;
          font-size: $fontsize-24;
          color: $color-white;
          text-transform: uppercase;
          background: $color-red;
          background-image: $bgTexure;
          outline: none;
          transition: background 0.5s;
          -webkit-transition: background 0.5s;
        }

        .loButton:hover {
          background: $color-red-dark;
          cursor: pointer;
        }

        @media ($smaller-than: $breakpoint-999) {
          li.loGetQuote {
            display: none;
          }

          .loButton {
            padding: 10px 20px;
            font-size: $fontsize-20;
          }
        }

        @media ($smaller-than: $breakpoint-599) {
          .loButton {
            padding: 8px 15px;
            font-size: $fontsize-16;
          }
        }
      }
    }

    @media ($smaller-than: $breakpoint-999) {
      .loContentBox {
        transform: translate(0%, 0%);
        width: calc(100% - 200px);
        width: -webkit-calc(100% - 200px);
        padding: 25px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        min-height: 360px;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      .loContentBox {
        width: calc(100% - 130px);
        width: -webkit-calc(100% - 130px);
        padding: 15px;
        min-height: 340px;
      }
    }
  }

  @media ($smaller-than: $breakpoint-999) {
    ul.loDescBlk {
      margin-top: -2px;
    }
  }
}

@media ($smaller-than: $breakpoint-999) {
  .LoanOptionBlk {
    padding: 50px 0px 20px 0px;
  }
}

/*Our Process*/
.ourProcessBlk {
  display: block;
  padding: 70px 0px 0px 0px;
  background-image: url(../img/process_bg.png),
    linear-gradient($color-lightYellow, $color-lightYellow1);
  background-position: bottom right;
  background-repeat: no-repeat;

  .ourProcess_blockDesc {
    display: inline-block;
    font-family: $font-agaramondB;
    font-size: $fontsize-16;
    color: $color-blackbrown-lighter;
    background: $color-offwhitelight;
    text-transform: uppercase;
    padding-left: 15px;
    margin-bottom: 5px;
    height: 30px;
    line-height: 33px;

    .curveShape {
      float: right;
      width: 30px;
      border-right: 15px solid $color-lightYellow;
      border-bottom: 30px solid $color-offwhitelight;
    }
  }

  .ourProcess_blkHeadTxt {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-42;
    color: $color-blackbrown-light;
    margin-bottom: 40px;
    padding-left: 15px;
    text-transform: capitalize;
  }

  @media ($smaller-than: $breakpoint-999) {
    .ourProcess_blkHeadTxt {
      font-size: $fontsize-30;
    }
  }

  ul.ourProcessIIblk {
    display: block;

    li.ourProcessIIblk {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2);
      width: -webkit-calc(100% / 2);
    }

    @media ($smaller-than: $breakpoint-999) {
      li.ourProcessIIblk {
        display: block;
        width: 80%;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      li.ourProcessIIblk {
        width: 100%;
      }
    }

    ul.ourProcessPointContentBlk {
      display: block;

      li.ourProcessPointContentBlk {
        display: inline-block;
        vertical-align: top;
      }

      li.ourProcessPointContentBlk:nth-child(odd) {
        width: 25px;
        margin-right: 25px;
        padding-top: 20px;
        background: linear-gradient($color-lighterblack, $color-lighterblack)
          repeat-y center/1px 100%;
        height: 150px;
      }

      li.ourProcessPointContentBlk:nth-child(even) {
        width: calc(100% - 50px);
        width: -webkit-calc(100% - 50px);
      }

      li.ourProcessPointContentBlk:nth-child(5) {
        height: 90px;
      }

      @media ($smaller-than: $breakpoint-599) {
        li.ourProcessPointContentBlk:nth-child(odd) {
          height: 60px;
        }
      }
    }

    .ourProcessCircle {
      width: 25px;
      aspect-ratio: 1/1;
      background: $color-red;
      border-radius: 50%;
      border: 6px solid $color-red-lightest;
    }

    .ourProcessColTxtHead,
    .ourProcessColTxt {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-30;
      color: $color-blackbrown-light;
      margin-bottom: 5px;
    }

    .ourProcessColTxt {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-20;
      color: $color-lightblack;
      margin-bottom: 50px;
      line-height: 25px;
    }

    @media ($smaller-than: $breakpoint-799) {
      .ourProcessCircle {
        width: 20px;
      }

      .ourProcessColTxtHead {
        font-size: $fontsize-22;
      }

      .ourProcessColTxt {
        font-size: $fontsize-16;
        margin-bottom: 20px;
        line-height: normal;
      }
    }
  }
}

@media ($smaller-than: $breakpoint-1023) {
  .ourProcessBlk {
    padding: 50px 0px 20px 0px;
    background-image: none;
    background: $color-lightYellow;
  }
}

/*Our Service*/
.ourServiceBlk {
  display: block;
  padding: 70px 0px;

  .ourService_blockDesc {
    display: inline-block;
    font-family: $font-agaramondB;
    font-size: $fontsize-16;
    color: $color-blackbrown-lighter;
    background: $color-offwhite;
    text-transform: uppercase;
    padding-left: 15px;
    margin-bottom: 5px;
    height: 30px;
    line-height: 33px;

    .curveShape {
      float: right;
      width: 30px;
      border-right: 15px solid $color-white;
      border-bottom: 30px solid $color-offwhite;
    }

    @media ($smaller-than: $breakpoint-1200) {
      .curveShape {
        display: none;
      }
    }
  }

  .ourService_blkHeadTxt {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-40;
    color: $color-blue-darker;
    margin-bottom: 30px;
    padding-left: 15px;
    text-transform: capitalize;
  }

  .ourService_IIIcol {
    display: inline-block;
    vertical-align: top;
    border-top: 1.5px solid $color-red;
    height: 300px;
    width: calc(100% / 3 - 17px);
    width: -webkit-calc(100%/3 - 17px);
    margin-right: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    padding: 40px 55px;
    color: $color-blackbrown-light;
  }

  .lastCol {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-1200) {
    .ourService_blockDesc {
      display: inherit;
      width: 150px;
      margin: 0px auto 5px auto;
      padding: 0px;
      text-align: center;
    }

    .ourService_blkHeadTxt {
      font-size: $fontsize-30;
      text-align: center;
    }

    .ourService_IIIcol {
      display: block;
      margin: 0px auto 25px auto;
      width: 500px;
    }
  }

  @media ($smaller-than: $breakpoint-599) {
    .ourService_IIIcol {
      width: 100%;
    }
  }

  /*.hover-3 {
		--c: no-repeat linear-gradient(#e01919 0 0);
		background:
			var(--c) calc(-101% + var(--p, 0%)) 100% / 50.1% var(--p, .08em),
			var(--c) calc(201% - var(--p, 0%)) 0 / 50.1% var(--p, .08em);
		transition: .3s var(--t, 0s), background-position .3s calc(.3s - var(--t, 0s));
	}

	.hover-3:hover {
		--p: 101%;
		--t: 0.3s;
		color: $color-white;
	}

	.hover-2 {
		background: linear-gradient(#e01919 0 0) no-repeat calc(200% - var(--p, 0%)) 100% / 200% var(--p, 0em);
		transition: 0.3s var(--t, 0s), background-position 0.3s calc(0.3s - var(--t, 0s));
	}

	.hover-2:hover {
		--p: 100%;
		--t: 0.3s;
		color: $color-white;
	}
	
	.hover-1 {
		background: linear-gradient(#e01919 0 0) var(--p, 0%) / var(--p, 0%) no-repeat;
		transition: .4s, background-position 0s;
	}

	.hover-1.alt {
		background: linear-gradient(#e01919 0 0) calc(100% - var(--p, 0%)) / var(--p, 0%) no-repeat;
	}

	.hover-1:hover {
		--p: 100%;
		color: $color-white;
	}*/

  .hover_ourService {
    background: linear-gradient($color-red 0 0) calc(100% - var(--p, 0%)) /
      var(--p, 0%) no-repeat;
    transition: 0.4s, background-position 0s;
  }

  .hover_ourService.buy {
    background-image: url(../img/service1.jpg);
  }

  .hover_ourService.refinance {
    background-image: url(../img/service2.jpg);
  }

  .hover_ourService.credit {
    background-image: url(../img/service3.jpg);
  }

  .hover_ourService:hover {
    --p: 100%;
    color: $color-white;
  }

  .ourService_icon1,
  .ourService_icon2,
  .ourService_icon3 {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  .ourService_icon1 {
    background: $os_buyHomeIcon_SVG;
    background-size: 60px;
    background-position: center left;
  }

  .ourService_IIIcol:hover .ourService_icon1 {
    background: $os_buyHomeIcon_white_SVG;
    background-size: 60px;
    background-position: center left;
  }

  .ourService_icon2 {
    background: $os_refinanceIcon_SVG;
    background-size: 55px;
    background-position: center center;
  }

  .ourService_IIIcol:hover .ourService_icon2 {
    background: $os_refinanceIcon_white_SVG;
    background-size: 55px;
    background-position: center center;
  }

  .ourService_icon3 {
    background: $os_creditServiceIcon_SVG;
    background-size: 55px;
    background-position: center center;
  }

  .ourService_IIIcol:hover .ourService_icon3 {
    background: $os_creditServiceIcon_white_SVG;
    background-size: 55px;
    background-position: center center;
  }

  .ourService_colHeadTxt,
  .ourService_colTxt {
    display: block;
    font-family: $font-agaramondB;
    font-size: $fontsize-24;
    margin-bottom: 15px;
    text-transform: uppercase;
  }

  .ourService_colTxt {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-17;
    margin-bottom: 20px;
    text-transform: none;
  }

  .ourServiceButton {
    height: 32px;
    padding: 0px 15px;
    border: 0.5px solid $color-lighterblack;
    border-radius: 5px;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-15;
    outline: none;
    background: $color-white;
    color: $color-blackbrown-light;
    // transition: background .5s;
    // -webkit-transition: background .5s;
  }

  .ourServiceButton:hover {
    cursor: pointer;
    color: $color-black;
  }
}

@media ($smaller-than: $breakpoint-999) {
  .ourServiceBlk {
    padding: 40px 0px;
  }
}

/*Reach You*/
.reachYouBlk {
  display: block;
  padding: 60px 0px;
  background-size: cover;
  background: url(../img/reachyou_bg.jpg);

  .reachYou_blkHeadTxt {
    display: block;
    width: 60%;
    margin: 0px auto 40px auto;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-30;
    color: $color-white;
    text-align: center;
  }

  @media ($smaller-than: $breakpoint-999) {
    .reachYou_blkHeadTxt {
      font-size: $fontsize-20;
      width: 100%;
      margin-bottom: 25px;
    }
  }

  ul.reachYouForm {
    display: block;
    width: 90%;
    margin: 0px auto;

    li.reachYouForm {
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      background: $color-white;
    }

    li.reachYouForm:nth-child(1) {
      width: 275px;
    }

    li.reachYouForm:nth-child(2) {
      width: 250px;
    }

    li.reachYouForm:nth-child(3) {
      width: calc(100% - 705px);
      width: -webkit-calc(100% - 705px);
    }

    li.reachYouForm:last-child {
      width: 150px;
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-999) {
      li.reachYouForm:nth-child(1) {
        width: 175px;
      }

      li.reachYouForm:nth-child(2) {
        width: 175px;
      }

      li.reachYouForm:nth-child(3) {
        width: calc(100% - 550px);
        width: -webkit-calc(100% - 550px);
      }
    }

    @media ($smaller-than: $breakpoint-799) {
      li.reachYouForm {
        margin-bottom: 10px;
      }

      li.reachYouForm:nth-child(1),
      li.reachYouForm:nth-child(2) {
        width: calc(100% / 2 - 5px);
        width: -webkit-calc(100%/2 - 5px);
      }

      li.reachYouForm:nth-child(2) {
        margin-right: 0px;
      }

      li.reachYouForm:nth-child(3) {
        width: calc(100% - 160px);
        width: -webkit-calc(100% - 160px);
      }

      li.reachYouForm:nth-child(4) {
        width: 150px;
        margin-right: 0px;
      }
    }

    ::-webkit-input-placeholder {
      color: $color-lightblack;
    }

    :-moz-placeholder {
      color: $color-lightblack;
    }

    ::-moz-placeholder {
      color: $color-lightblack;
    }

    :-ms-input-placeholder {
      color: $color-lightblack;
    }

    input[type="text"],
    input[type="email"] {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-24;
      color: $color-blackbrown-light;
      width: 100%;
      padding: 0px 10px 0px 15px;
      height: 60px;
      border: none;
      border-radius: 3px;
      background-image: $bgTexure;
      outline: 0;
    }

    .reachYouButton {
      width: 100%;
      height: 60px;
      border: none;
      border-radius: 2px;
      font-family: $font-times;
      font-weight: bold;
      font-size: $fontsize-20;
      color: $color-blackbrown-light;
      text-transform: uppercase;
      background: $color-white;
      background-image: $bgTexure;
      outline: none;
      transition: background 0.5s;
      -webkit-transition: background 0.5s;
    }

    .reachYouButton:hover {
      color: $color-blackbrown;
      cursor: pointer;
    }

    @media ($smaller-than: $breakpoint-599) {
      input[type="text"],
      input[type="email"] {
        font-size: $fontsize-20;
        height: 45px;
      }

      .reachYouButton {
        height: 45px;
      }
    }
  }

  @media ($smaller-than: $breakpoint-1200) {
    ul.reachYouForm {
      width: 100%;
    }
  }
}

@media ($smaller-than: $breakpoint-999) {
  .reachYouBlk {
    padding: 25px 0px;
  }
}

/*Blogs*/
.blogBlk {
  display: block;
  padding: 70px 0px 70px 0px;

  .blog_blkTxtDesc,
  .blog_blkHead {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-22;
    color: $color-red;
    margin-bottom: 5px;
    text-align: center;
  }

  .blog_blkHead {
    font-size: $fontsize-40;
    color: $color-blue-darker;
    margin-bottom: 50px;
  }

  @media ($smaller-than: $breakpoint-999) {
    .blog_blkTxtDesc {
      font-size: $fontsize-18;
    }

    .blog_blkHead {
      font-size: $fontsize-30;
    }
  }

  ul.blog_IIIcol {
    display: block;

    li.blog_IIIcol {
      display: inline-block;
      vertical-align: top;
      border-top: 0.25px solid $color-offblack-light;
      width: calc(100% / 3 - 17px);
      width: -webkit-calc(100%/3 - 17px);
      margin-right: 25px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
      padding-bottom: 40px;
    }

    li.blog_IIIcol:last-child {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-999) {
      li.blog_IIIcol,
      li.blog_IIIcol:last-child {
        display: block;
        width: 50%;
        margin: 0px auto 25px auto;
      }
    }

    @media ($smaller-than: $breakpoint-599) {
      li.blog_IIIcol,
      li.blog_IIIcol:last-child {
        width: 100%;
      }
    }

    .blogImg1 {
      display: block;
      background: url(../img/blog_img1.jpg);
      height: 250px;
      background-size: cover;
      margin-bottom: 15px;
    }

    .blogImg2 {
      display: block;
      background: url(../img/blog_img2.jpg) no-repeat;
      height: 250px;
      background-size: cover;
      margin-bottom: 15px;
    }

    .blogImg3 {
      display: block;
      background: url(../img/blog_img3.jpg) no-repeat;
      height: 250px;
      background-size: cover;
      margin-bottom: 15px;
    }

    .blogdateMonthBlk {
      position: relative;
      float: right;
      top: 185px;
      right: 20px;
      width: 70px;
      height: 80px;
      background: $color-red;
      text-align: center;

      .blogDate {
        font-family: $font-times;
        font-size: $fontsize-30;
        font-weight: bold;
        color: $color-white;
        margin: 12px 0px 0px 0px;
      }

      .blogMonth {
        font-family: $font-times;
        font-size: $fontsize-18;
        font-weight: bold;
        color: $color-white;
        text-transform: uppercase;
      }
    }

    .blogColHead {
      display: block;
      font-family: $font-times;
      font-size: $fontsize-24;
      font-weight: bold;
      color: $color-blackbrown;
      padding: 30px 35px 20px 35px;
    }

    .blogColDesc {
      display: block;
      font-family: $font-times;
      font-size: $fontsize-17;
      color: $color-lightblack;
      padding: 0px 35px 10px 35px;
      text-align: justify;
    }

    .blogReadMore {
      display: block;
      padding-left: 35px;
      text-decoration: none;
    }

    .blogReadMore .readMoreLink {
      font-family: $font-agaramondB;
      font-size: $fontsize-18;
      color: $color-red;
      text-transform: capitalize;
    }

    .blogReadMore .readMoreLink:hover {
      color: $color-blackbrown-lighter;
    }

    .blogReadMore .readMoreLink::after {
      content: "\0000bb";
      margin-left: 10px;
      font-size: $fontsize-30;
      color: $color-red;
    }

    @media ($smaller-than: $breakpoint-1200) {
      .blogColHead {
        font-size: $fontsize-20;
        padding-bottom: 10px;
      }

      .blogColDesc {
        font-size: $fontsize-15;
      }

      .blogReadMore .readMoreLink {
        font-size: $fontsize-16;
      }
    }
  }
}

@media ($smaller-than: $breakpoint-999) {
  .blogBlk {
    padding: 50px 0px 20px 0px;
  }
}

/*Calculate Payment*/
.paymentBlk {
  display: block;
  padding: 60px 0px;
  background-size: cover;
  background: url(../img/payment_bg.jpg);

  ul.paymentIIcol {
    display: block;

    li.paymentIIcol {
      display: inline-block;
      vertical-align: top;
    }

    li.paymentIIcol:first-child {
      width: calc(80% / 2);
      width: -webkit-calc(80% /2);
    }

    li.paymentIIcol:last-child {
      width: calc(120% / 2);
      width: -webkit-calc(120% /2);
      background: $color-white;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.paymentIIcol,
      li.paymentIIcol:first-child,
      li.paymentIIcol:last-child {
        display: block;
        width: 100%;
      }

      li.paymentIIcol:last-child {
        margin: 0px auto;
        width: 90%;
        margin-bottom: 20px;
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.paymentIIcol:last-child {
        width: 98%;
      }
    }
  }

  .payment_blkHeadTxt {
    display: block;
    margin: 45px 0px 25px 0px;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-38;
    color: $color-white;
  }

  .payment_blkDescTxt {
    display: block;
    width: 90%;
    font-family: $font-times;
    font-size: $fontsize-20;
    color: $color-white;
    line-height: 30px;
  }

  @media ($smaller-than: $breakpoint-1200) {
    .payment_blkHeadTxt {
      text-align: center;
      font-size: $fontsize-30;
      margin: 25px 0px 0px 0px;
    }

    .payment_blkDescTxt {
      width: 100%;
      font-size: $fontsize-16;
      text-align: center;
      line-height: normal;
      margin-bottom: 20px;
    }
  }

  ul.paymentPoints {
    display: block;
    padding: 30px 40px;

    li.paymentPoints {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 48px);
      width: -webkit-calc(100% /2 - 48px);
      margin-right: 35px;
      border-bottom: 2px dashed $color-blue-bg-lightest;
      margin-left: 30px;
      padding: 10px 0px;
    }

    li.paymentPoints .paymentLink {
      font-family: $font-times;
      font-size: $fontsize-19;
      font-weight: bold;
      color: $color-blue-text;
    }

    li.paymentPoints .paymentLink:hover {
      color: $color-red;
    }

    li.paymentPoints:nth-child(even) {
      margin-right: 0px;
    }

    li.paymentPoints::before {
      content: "\01f53f";
      color: $color-red;
      font-size: $fontsize-20;
      margin-left: -30px;
      margin-right: 10px;
    }

    li.paymentPoints:first-child,
    li.paymentPoints:nth-child(2) {
      padding-top: 0px;
    }

    li.paymentPoints:last-child,
    li.paymentPoints:nth-last-child(2) {
      border-bottom: none;
      padding-bottom: 0px;
    }

    @media ($smaller-than: $breakpoint-699) {
      li.paymentPoints {
        width: calc(100% / 2 - 25px);
        width: -webkit-calc(100% /2 - 25px);
        margin-right: 20px;
        margin-left: 15px;
        padding: 5px 0px;
      }

      li.paymentPoints .paymentLink {
        font-size: $fontsize-14;
      }
    }
  }

  @media ($smaller-than: $breakpoint-1200) {
    ul.paymentPoints {
      padding: 10px 20px;
    }
  }
}

@media ($smaller-than: $breakpoint-1200) {
  .paymentBlk {
    padding: 5px 0px;
  }
}

/*Testimonial*/
.testiBlk {
  display: block;
  padding: 70px 0px 70px 0px;

  .testiIcon {
    width: 100px;
    height: 120px;
    margin: 0px auto 5px auto;
    background: $testi_SVG;
    background-size: 100px;
    background-position: center center;
  }

  .testi_blkHead,
  .testi_blkTxtDesc {
    display: block;
    font-family: $font-times;
    font-weight: bold;
    font-size: $fontsize-40;
    color: $color-blue-darker;
    margin-bottom: 5px;
    text-align: center;
  }

  .testi_blkTxtDesc {
    font-size: $fontsize-18;
    color: $color-lightblack;
    margin-bottom: 40px;
    font-weight: normal;
  }

  @media ($smaller-than: $breakpoint-999) {
    .testiIcon {
      width: 100px;
      height: 80px;
      background-size: 70px;
      background-position: center center;
    }

    .testi_blkHead {
      font-size: $fontsize-30;
    }

    .testi_blkTxtDesc {
      font-size: $fontsize-16;
      margin-bottom: 30px;
    }
  }
}

@media ($smaller-than: $breakpoint-1023) {
  .testiBlk {
    padding: 50px 0px 20px 0px;
  }
}

/*body > #containerHome ,root > #containerHome{
    height: auto;
    min-height: 100%;
  }
#containerHome {
  color: $color-black;
  height: 100%;
  background-image: $bgTexure;

  #contentHome {
    padding-top: 95px;
    padding-bottom: 260px;
  }
  @media ($smaller-than: $breakpoint-999) {
    #contentHome {
	  padding-top: 85px;
    }
  }
}*/

#homeLandingView {
  margin: 0px;
  width: 100%;
  height: 100%;
  /*background-image: $bgTexure;*/

  /*.videoContainer {
	position: relative;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	z-index: 10;
	botom: 0;
	right: 0;
	opacity:.3;
  }
  .videoContainer video {
	display:block;
	top: 0%;
	left: 0%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transition: 1s opacity;
  }
  .videoContainer video::-webkit-media-controls {
	display: none !important
  }
  .video1-bg{
	position: absolute;
	right: 0;
	bottom: 0;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	z-index: -100;
	background: transparent url(../img/video1-fallbk.jpg) no-repeat;
	background-size: cover;
  }*/

  .homeTxtRateBlock {
    position: absolute;
    top: 15%;
    left: 50%;
    width: 100%;
    margin-left: -50%;
    z-index: 15;

    ul.home_II_col {
      display: block;
      margin: 0px;
      padding: 0px;

      li.home_II_col {
        display: inline-block;
        vertical-align: top;
        width: calc(115% / 2 - 3px);
        width: -webkit-calc(115%/2 - 3px);
      }

      li.home_II_col:last-child {
        width: calc(85% / 2 - 3px);
        width: -webkit-calc(85%/2 - 3px);
        margin-right: 0px;
      }

      @media ($smaller-than: $breakpoint-899) {
        li.home_II_col,
        li.home_II_col:last-child {
          display: block;
          width: 100%;
        }

        /*li.home_II_col:last-child {
		  display: none;
  	    }*/
      }
    }

    .mortgageRateBlock {
      display: block;

      .mortgageRateHead {
        display: block;
        text-align: center;
        padding: 9px 0;
        margin-bottom: -3px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 0px solid $color-offwhitelight;
        font-family: $font-agaramondB;
        font-size: $fontsize-26;
        color: $color-white;
        background: $color-blue;
        /*$color-blue-navi-light;*/
        background-image: $bgTexure;
        /*color: $color-blue-navi-dark;
		background: $color-white;*/
        box-shadow: 0px 0px 4px rgba(14, 89, 122, 0.4);
      }

      .mortgageMain {
        display: block;
        background: $color-white;
        padding: 18px 0 25px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid $color-offwhitelight;
        box-shadow: 0px 1px 4px rgba(14, 89, 122, 0.3);

        ul.pur_refin_link {
          display: block;
          width: 250px;
          margin: 0px auto;
          border-radius: 5px;
          border: 1px solid $color-blue-navi-light;

          li.pur_refin_link {
            display: inline;
          }

          li.pur_refin_link:last-child {
            margin-left: -4px;
          }

          li a {
            display: inline-block;
            vertical-align: top;
            width: calc(100% / 2 - 0px);
            width: -webkit-calc(100%/2 - 0px);
            font: $fontsize-18 $font-agaramondB;
            color: $color-lightblack;
            text-align: center;
            text-decoration: none;
            padding: 7px 0 6px 0;
            transition: color 0.5s;
            -webkit-transition: color 0.5s;
          }

          li a:hover {
            color: $color-blue;
          }

          li a.current {
            position: relative;
            color: $color-white;
            background: $color-blue-navi-light;
            background-image: $bgTexure;
            box-shadow: none;
          }
        }

        ul.pinRate {
          display: block;
          margin: 23px 45px;
          padding: 0px;

          :-webkit-input-placeholder {
            color: $color-lightblack;
          }

          :-moz-placeholder {
            color: $color-lightblack;
          }

          ::-moz-placeholder {
            color: $color-lightblack;
          }

          :-ms-input-placeholder {
            color: $color-lightblack;
          }

          input[type="text"] {
            height: 45px;
            width: 100%;
            padding: 12px 10px 10px 15px;
            border-radius: 3px;
            border: 1px solid $color-disable;
            background-image: $bgTexure;
            font-family: $font-agaramondB;
            font-size: $fontsize-19;
            color: $color-blackbrown;
            outline: 0;
          }

          li.pinRate {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 190px);
            width: -webkit-calc(100% - 190px);
            margin-right: 5px;
          }

          li.pinRate:last-child {
            width: 180px;
            margin-right: 0px;
          }

          .pinRateBut {
            width: 100%;
            height: 45px;
            border: none;
            border-radius: 3px;
            font-family: $font-agaramondB;
            font-size: $fontsize-19;
            color: $color-white;
            background: $color-blue-navi-light;
            background-image: $bgTexure;
            outline: none;
            transition: background 0.5s;
            -webkit-transition: background 0.5s;
          }

          .pinRateBut:hover {
            background: $color-blue-navi;
            cursor: pointer;
          }
        }

        .mortgageGeneral {
          display: block;
          margin: 0px 45px;

          .generalHead {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-15;
            color: $color-blackbrown;
            background: $color-blue-bg-light;
            background-image: $bgTexure;
            padding: 3px 0;
            text-align: center;
            border: 1px solid $color-blue-bg;
            border-bottom: none;
          }

          ul.general_III_col {
            display: block;
            margin: 0px 0px 10px 0px;
            padding: 0px;
            border: 1px solid $color-blue-bg;

            li.general_III_col {
              display: inline-block;
              vertical-align: top;
              width: calc(100% / 3 - 3px);
              width: -webkit-calc(100%/3 - 3px);
              border-right: 1px solid $color-blue-bg;
              text-align: center;
            }

            li.general_III_col:last-child,
            li.general_III_col:nth-child(3) {
              border-right: none;
            }
          }

          .mortgagePointHead {
            display: block;
            font-family: $font-agaramondB;
            font-size: $fontsize-14;
            color: $color-blackbrown;
            padding: 2px 0;
          }
        }
      }
    }
  }

  @media ($smaller-than: $breakpoint-699) {
    .homeTxtRateBlock {
      top: 10%;
    }
  }
}

/* For screen width smaller than 899px */
@media screen and (max-width: 899px) {
  #homeLandingView {
    height: 60%;
  }
}

#bottom-bar {
  display: block;
  visibility: visible;
  width: 100%;
  height: 200px;
  border-top: 1px solid $color-offwhite;
  background: $color-white;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 110;

  ul.home_IV_col {
    display: block;
    margin: 0px;
    padding: 0px;

    li.home_IV_col {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 4 - 0px);
      width: -webkit-calc(100%/4 - 0px);
      padding: 30px 15px 24px 15px;
      margin-left: -4px;
      transition: background 0.8s;
      -webkit-transition: background 0.8s;
    }

    li.home_IV_col:hover {
      background: $color-white;
      background-image: $bgTexure;
      transform: scale(1.01);
    }

    @media ($smaller-than: $breakpoint-899) {
      li.home_IV_col {
        width: calc(100% / 2 - 0px);
        width: -webkit-calc(100%/2 - 0px);
      }
    }

    @media ($smaller-than: $breakpoint-699) {
      li.home_IV_col {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  .bottomTxtHead {
    display: block;
    text-align: center;
    font-family: $font-agaramondB;
    font-size: $fontsize-23;
    color: $color-blue-dark;
    margin-bottom: 4px;
  }

  .bottomTxtDesc {
    display: block;
    text-align: center;
    font-family: $font-agaramond;
    font-size: $fontsize-15;
    color: $color-blackbrown-light;
  }

  .bottomIcon1,
  .bottomIcon2,
  .bottomIcon3,
  .bottomIcon4 {
    width: 100px;
    height: 70px;
    margin: 0px auto 7px auto;
  }

  .bottomIcon1 {
    background: $purchaseIcon_SVG;
    background-size: 80px;
    background-position: center center;
  }

  .bottomIcon2 {
    background: $refinanceIcon_SVG;
    background-size: 80px;
    background-position: center center;
  }

  .bottomIcon3 {
    background: $ratesIcon_SVG;
    background-size: 85px;
    background-position: center center;
  }

  .bottomIcon4 {
    background: $calculatorIcon_SVG;
    background-size: 70px;
    background-position: center center;
  }

  @media ($smaller-than: $breakpoint-899) {
  }
}

/* For screen height smaller than 700px */
@media screen and (max-height: 699px) {
  #bottom-bar {
    display: none;
  }
}

/* For screen width smaller than 899px */
@media screen and (max-width: 899px) {
  #bottom-bar {
    visibility: normal;
    height: auto;
    position: inherit;
  }
}

/* For screen bigger than 900px */
@media screen and (min-width: 900px) {
  #bottom-bar {
    top: auto;
    bottom: 0px;
    position: absolute;
  }
}

#rateQuoteBlock {
  display: block;
  background: $color-blue-logo;
  background-image: $bgTexure;
  padding: 80px 0 80px 0;

  ul.rateQuote_shortLink {
    display: block;
    margin: 0px;
    padding: 0px;

    li.rateQuote_shortLink {
      display: inline-block;
      vertical-align: top;
      width: calc(75% / 2 - 21px);
      width: -webkit-calc(75%/2 - 21px);
      margin-right: 20px;
    }

    li.rateQuote_shortLink:last-child {
      width: calc(125% / 2 - 3px);
      width: -webkit-calc(125%/2 - 3px);
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-899) {
      li.rateQuote_shortLink,
      li.rateQuote_shortLink:last-child {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  .rateQuote {
    display: block;
    background: $color-whiteblue;
    background-image: $bgTexure;
    margin: 0px 0px 0px 0px;

    .rateQuoteHead {
      display: block;
      text-align: center;
      padding: 15px 0 11px 0;
      font-family: $font-agaramondB;
      font-size: $fontsize-30;
      color: $color-blue-navi-dark;
      border-bottom: 3px solid $color-blue-logo;
    }

    ul.rateQuote_Form {
      display: block;
      margin: 0px;
      padding: 10px 25px 23px 25px;

      li.rateQuote_Form {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 10px);
        width: -webkit-calc(100%/2 - 10px);
        margin-right: 15px;
      }

      li.rateQuote_Form:nth-child(even) {
        margin-right: 0px;
      }

      .formHead {
        display: block;
        font-family: $font-agaramondB;
        font-size: $fontsize-16;
        color: $color-blue-dark;
        margin: 15px 0 3px 0;
      }

      ::-webkit-input-placeholder {
        color: $color-offblack;
      }

      :-moz-placeholder {
        color: $color-offblack;
      }

      ::-moz-placeholder {
        color: $color-offblack;
      }

      :-ms-input-placeholder {
        color: $color-offblack;
      }

      select:required:invalid {
        color: $color-offblack;
      }

      option {
        color: $color-offblack;
      }

      select::-ms-expand {
        display: none;
      }

      select {
        width: 100%;
        font: $fontsize-15 $font-agaramondB;
        color: $color-blackbrown-light;
        border: 1px solid $color-blue-lighter;
        border-radius: 5px;
        outline: 0;
        padding: 13px 10px;
        background: $selectarrow_blueIcon_SVG;
        background-position: right 4% center;
        background-color: $color-white;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      select:hover {
        cursor: pointer;
        background: $selectarrow_blueIcon_SVG;
        background-position: right 4% center;
        background-color: $color-white;
      }

      input[type="text"] {
        display: block;
        font: $fontsize-15 $font-agaramondB;
        color: $color-blackbrown-light;
        width: 100%;
        padding: 11px 10px;
        border: 1px solid $color-blue-lighter;
        border-radius: 5px;
        background: $color-white;
        outline: 0;
      }

      .rateQuote_button_label {
        display: block;
        margin: 15px 0 0 0;

        .rateQuoteBut {
          width: 100px;
          height: 38px;
          border: none;
          border-radius: 2px;
          font-family: $font-agaramondB;
          font-size: $fontsize-18;
          color: $color-white;
          background: $color-blue;
          background-image: $bgTexure;
          outline: none;
          transition: background 0.5s;
          -webkit-transition: background 0.5s;
        }

        .rateQuoteBut:hover {
          background: $color-blue-navi;
          cursor: pointer;
        }

        .rateQuoteBut[disabled],
        .rateQuoteBut[disabled]:hover,
        .rateQuoteBut.disabled,
        .rateQuoteBut.disabled:hover {
          background: $color-lightblack;
          color: $color-offblack;
          cursor: default;
          text-shadow: none;
          position: static;
          -moz-box-shadow: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          border: 0px dotted $color-disable;
        }
      }
    }
  }

  ul.srtlink {
    display: block;
    margin: 0px;
    padding: 0px;

    li.srtlink {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 12px);
      width: -webkit-calc(100%/2 - 12px);
      margin-right: 20px;
      padding: 25px 25px 25px 25px;
      margin-bottom: 25px;
      text-align: center;
      transition: background 0.7s;
      -webkit-transition: background 0.7s;
    }

    li.srtlink:nth-child(even) {
      margin-right: 0px;
    }

    li.srtlink:nth-last-child(2),
    li.srtlink:last-child {
      margin-bottom: 0px;
    }

    li.srtlink:hover {
      background: $color-blue-dark;
      cursor: pointer;
    }

    @media ($smaller-than: $breakpoint-899) {
      li.srtlink {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }

    .srtLinkIcon1,
    .srtLinkIcon2,
    .srtLinkIcon3,
    .srtLinkIcon4 {
      width: 100px;
      height: 90px;
      margin: 0px auto 15px auto;
    }

    .srtLinkIcon1 {
      background: $aboutIcon_SVG;
      background-size: 90px;
      background-position: center center;
    }

    .srtLinkIcon2 {
      background: $programIcon_SVG;
      background-size: 90px;
      background-position: center center;
    }

    .srtLinkIcon3 {
      background: $testiIcon_SVG;
      background-size: 90px;
      background-position: center center;
    }

    .srtLinkIcon4 {
      background: $applyIcon_SVG;
      background-size: 90px;
      background-position: center center;
    }

    .srtLinkHead {
      display: block;
      text-align: center;
      font-family: $font-agaramondB;
      font-size: $fontsize-23;
      color: $color-white;
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .srtLinkDesc {
      display: block;
      text-align: center;
      font-family: $font-agaramond;
      font-size: $fontsize-16;
      color: $color-whiteblue;
    }
  }
}

#mortgageRateBlock {
  display: block;
  background: $color-white;
  background-image: $bgTexure;
  padding: 50px 0 100px 0;
  border-top: 1px solid $color-offwhite;

  ul.mortRate_II_col {
    display: block;
    margin: 0px;
    padding: 0px;

    li.mortRate_II_col {
      display: inline-block;
      vertical-align: top;
      width: calc(115% / 2 - 12px);
      width: -webkit-calc(115%/2 - 12px);
      margin-right: 20px;
    }

    li.mortRate_II_col:last-child {
      width: calc(85% / 2 - 12px);
      width: -webkit-calc(85%/2 - 12px);
      margin-right: 0px;
      padding-right: 0px;
      background: $color-white;
      padding: 25px 35px;
      border-radius: 10px;
      border: 1px solid $color-offwhitelight;
      box-shadow: 0px 2px 4px rgba(14, 89, 122, 0.1);
    }

    @media ($smaller-than: $breakpoint-899) {
      li.mortRate_II_col,
      li.mortRate_II_col:last-child {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  .mortRate_head {
    display: block;
    text-align: center;
    margin-bottom: 60px;
    font-family: $font-agaramondB;
    font-size: $fontsize-42;
    color: $color-blue-navi-dark;
  }

  .mortRate_itemHead {
    display: block;
    margin-bottom: 5px;
    font-family: $font-agaramondB;
    font-size: $fontsize-15;
    color: $color-blue-navi-dark;
  }

  ::-webkit-input-placeholder {
    color: $color-offblack;
  }

  :-moz-placeholder {
    color: $color-offblack;
  }

  ::-moz-placeholder {
    color: $color-offblack;
  }

  :-ms-input-placeholder {
    color: $color-offblack;
  }

  select:required:invalid {
    color: $color-offblack;
  }

  option {
    color: $color-offblack;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 100%;
    font: $fontsize-17 $font-times;
    font-weight: bold;
    color: $color-blackbrown-light;
    border: 1px solid $color-blue-navi-light;
    border-radius: 5px;
    outline: 0;
    padding: 0px 15px;
    height: 50px;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 4% center;
    background-color: $color-white;
    background-size: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blackIcon_SVG;
    background-position: right 4% center;
    background-color: $color-white;
    background-size: 20px;
  }

  input[type="text"] {
    display: block;
    font: $fontsize-17 $font-times;
    font-weight: bold;
    color: $color-blackbrown-light;
    width: 100%;
    height: 48px;
    padding: 0px 5px;
    border: none;
    border-radius: 5px;
    background: $color-white;
    outline: 0;
  }

  .textZip > input[type="text"] {
    padding-left: 10px;
    letter-spacing: 3px;
  }

  .textdollar > input[type="text"] {
    padding-left: 27px;
  }

  .txtLabel {
    display: block;
    border: 1px solid $color-blue-navi-light;
    border-radius: 5px;
  }

  .percentIcon,
  .dollarIcon {
    position: absolute;
    float: left;
    width: 35px;
    height: 48px;
    margin-left: 36px;
    font-family: $font-times;
    font-size: $fontsize-17;
    font-weight: bold;
    color: $color-blackbrown-light;
    text-align: center;
    line-height: 48px;
  }

  .dollarIcon {
    margin-left: 0px;
  }

  ul.mortRate_pur_refin {
    display: block;
    margin: 0px;
    background: $color-white;
    border-radius: 5px;
    border: 1px solid $color-blue-navi-light;

    li.mortRate_pur_refin {
      display: inline;
    }

    li.mortRate_pur_refin:last-child {
      margin-left: -4px;
    }

    li a {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 0px);
      width: -webkit-calc(100%/2 - 0px);
      font: $fontsize-17 $font-times;
      font-weight: bold;
      color: $color-lightblack;
      text-align: center;
      text-decoration: none;
      padding: 0px 0 0px 0;
      height: 48px;
      line-height: 49px;
      transition: color 0.5s;
      -webkit-transition: color 0.5s;
    }

    li a:hover {
      color: $color-blue;
    }

    li a.current {
      position: relative;
      color: $color-white;
      background: $color-blue-navi-light;
      background-image: $bgTexure;
      box-shadow: none;
    }
  }

  ul.mortRate_II_col_downpayment {
    display: block;
    margin: 0px;
    padding: 0px;
    background: $color-white;
    border-radius: 5px;
    border: 1px solid $color-blue-navi-light;

    li.mortRate_II_col_downpayment {
      display: inline-block;
      vertical-align: top;
      width: calc(130% / 2 - 3px);
      width: -webkit-calc(130%/2 - 3px);
      border-right: 1px solid $color-blue-navi-light;
    }

    li.mortRate_II_col_downpayment:last-child {
      width: calc(70% / 2 - 3px);
      width: -webkit-calc(70%/2 - 3px);
      margin-right: 0px;
      border-right: none;
    }
  }

  ul.mortRate_III_col_first {
    display: block;
    margin: 0 0 28px 0;
    padding: 0px;

    li.mortRate_III_col_first {
      display: inline-block;
      vertical-align: top;
      width: calc(136% / 3 - 13px);
      width: -webkit-calc(136%/3 - 13px);
      margin-right: 15px;
    }

    li.mortRate_III_col_first:nth-child(2) {
      width: calc(70% / 3 - 13px);
      width: -webkit-calc(70%/3 - 13px);
    }

    li.mortRate_III_col_first:last-child {
      width: calc(90% / 3 - 13px);
      width: -webkit-calc(90%/3 - 13px);
      margin-right: 0px;
    }
  }

  ul.mortRate_III_col_second {
    display: block;
    margin: 0 0 0 0;
    padding: 0px;

    li.mortRate_III_col_second {
      display: inline-block;
      vertical-align: top;
      width: calc(103% / 3 - 13px);
      width: -webkit-calc(103%/3 - 13px);
      margin-right: 15px;
    }

    li.mortRate_III_col_second:last-child {
      margin-right: 0px;
    }
  }

  .mortRateResult {
    display: block;

    .result_headTxt {
      display: block;
      text-align: center;
      font-family: $font-agaramondB;
      font-size: $fontsize-15;
      color: $color-blue-navi-dark;
    }

    .result_descTxt {
      display: block;
      text-align: center;
      margin-bottom: 15px;
      font-family: $font-agaramond;
      font-size: $fontsize-18;
      color: $color-blue-logo;
    }

    ul.mortResult_III_col {
      display: block;
      margin: 0 0 0 0;
      padding: 0px;

      li.mortResult_III_col {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 3 - 13px);
        width: -webkit-calc(100%/3 - 13px);
        margin-right: 15px;
        background: $color-blue-navi-light;
        background-image: $bgTexure;
        border-radius: 15px;
        min-height: 80px;
      }

      li.mortResult_III_col:last-child {
        margin-right: 0px;
      }
    }

    .result_blkHead {
      display: block;
      text-align: center;
      margin: 12px 0 0px 0;
      font-family: $font-agaramond;
      font-size: $fontsize-13;
      color: $color-white;
    }

    .result_blkTxt {
      display: block;
      text-align: center;
      font-family: $font-agaramondB;
      font-size: $fontsize-38;
      color: $color-white;

      .symbol {
        position: absolute;
        font-size: $fontsize-7;
      }

      .symbolDol {
        position: relative;
        font-size: $fontsize-8;
        left: -3px;
      }
    }

    .result_dateTxt {
      display: block;
      text-align: center;
      margin: 10px 0 10px 0;
      font-family: $font-agaramond;
      font-size: $fontsize-14;
      color: $color-lightblack;
    }

    ul.result_lenderLogoNMLS {
      display: block;
      width: 75%;
      margin: 0 auto;

      li.result_lenderLogoNMLS {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 2 - 13px);
        width: -webkit-calc(100%/2 - 13px);
        margin-right: 10px;
      }

      li.result_lenderLogoNMLS:last-child {
        margin-right: 0px;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-blackbrown-light;
        line-height: 40px;
      }
    }

    .lenderLogo {
      display: block;
      float: right;
      width: 90px;
      height: auto;
    }

    .lenderLogo img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      min-height: 100%;
      min-width: 100%;
      position: relative;
      right: 0px;
    }

    .result_compareTxt {
      display: block;
      text-align: center;
      margin-top: 5px;
      font-family: $font-agaramond;
      font-size: $fontsize-15;
      color: $color-lightblack;
    }

    .result_compareTxt a {
      color: $color-blue;
      border-bottom: 1px dashed $color-blue;
    }
  }
}
