@import "support";

/*Page Structure
-------------------------------*/

.appPgStructure_Icol {
  display: block;
  margin: 0px;
  padding: 0px;
}

ul.appPgStructure_IIcol {
  display: block;
  margin: 0px;
  padding: 0px;

  li.appPgStructure_IIcol {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 2 - 13px);
    width: -webkit-calc(100%/2 - 13px);
    margin-right: 25px;
  }

  li.appPgStructure_IIcol:nth-child(even) {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.appPgStructure_IIcol {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

ul.appPgStructure_60by40 {
  display: block;
  margin: 0px;
  padding: 0px;

  li.appPgStructure_60by40 {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 500px);
    width: -webkit-calc(100% - 500px);
    margin: 0px;
  }

  li.appPgStructure_60by40:last-child {
    width: 475px;
    margin-left: 25px;
  }

  @media ($smaller-than: $breakpoint-1023) {
    li.appPgStructure_60by40,
    li.appPgStructure_60by40:last-child {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

ul.appPgStructure_300by100pc {
  display: block;
  margin: 0px;
  padding: 0px;

  li.appPgStructure_300by100pc {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin-bottom: 20px;
  }

  li.appPgStructure_300by100pc:last-child {
    width: calc(100% - 320px);
    width: -webkit-calc(100% - 320px);
    margin-left: 20px;
  }

  @media ($smaller-than: $breakpoint-899) {
    li.appPgStructure_300by100pc,
    li.appPgStructure_300by100pc:last-child {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}

/*Box Shadow
------------------------------*/
.app_box_shadow,
.app_box_shadow_noPad,
.app_box_shadow_alert {
  display: block;
  padding: 20px 18px;
  background: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  margin-bottom: 25px;
}

.app_box_shadow_noPad {
  padding: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
}

.app_box_shadow_alert {
  box-shadow: 0px 0px 8px rgba(223, 31, 38, 0.35);
  margin-bottom: 25px;
  padding: 15px 18px 15px 18px;
  font-weight: bold;
}

/*Bread Crumb Top Post Login
------------------------------*/

ul.topBreadcrumb {
  display: block;
  background: $color-white;
  margin: 20px 0px -10px 0px;
  padding: 3px 10px 2px 10px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);

  li.topBreadcrumb_home {
    display: inline-block;
    vertical-align: middle;
    width: 38px;
    height: 28px;
    background: $homeIcon_SVG;
    background-size: 15px;
    background-position: center right 21px;
    background-repeat: no-repeat;
  }
  li.topBreadcrumb_home::after {
    content: "\00BB";
    font-size: $fontsize-22;
    color: $color-blue;
    padding-left: 25px;
  }

  li.topBreadcrumb {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-arial;
    font-size: $fontsize-14;
    font-weight: bold;
    color: $color-blue;
    padding: 3px 8px 7px 0px;
  }
  li.topBreadcrumb:last-child {
    color: $color-blackbrown;
  }
  li.topBreadcrumb a {
    text-decoration: none;
    color: $color-blue;
  }
  li.topBreadcrumb::after {
    content: "\203A";
    font-size: $fontsize-22;
    padding-left: 9px;
  }
  li.topBreadcrumb:last-child::after {
    content: "";
    padding-left: 0px;
  }
}

/*Box Heading Style
------------------------------*/

.app_headBlk,
.app_headBlk_pad20 {
  display: block;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-offwhite;
  height: 35px;

  .app_headBlk_blkName_status,
  .app_headBlk_blkName {
    display: inline-block;
    vertical-align: top;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-20;
    color: $color-blue-darker;
    height: 25px;
    line-height: 25px;
  }

  .app_headBlk_blkName {
    font-size: $fontsize-16;
    color: $color-blackbrown;
  }

  .head_rightContent,
  .head_rightContent_add {
    float: right;
    padding: 0 10px;
    margin: 0px 0 0 5px;
    background: $color-blue;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-13;
    color: $color-white;
    border-radius: 3px;
    height: 25px;
    line-height: 25px;
  }

  .head_rightContent_add:before {
    content: "\002B";
    font-size: $fontsize-16;
    margin-right: 4px;
  }

  @media ($smaller-than: $breakpoint-899) {
    .head_rightContent_add {
      width: 85px;
      padding: 0 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .head_rightContent_add:before {
      content: "";
      margin-right: 0px;
    }
  }

  .head_rightContent a,
  .head_rightContent_add a {
    text-decoration: none;
    color: $color-white;
  }

  .head_rightContent a:hover,
  .head_rightContent_add a:hover {
    color: $color-offwhite;
  }

  select:required:invalid {
    color: $color-lightblack;
  }

  option {
    color: $color-lightblack;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 120%;
    height: 28px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-20;
    color: $color-blue;
    border: 0px;
    outline: 0;
    background-color: transparent;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    color: $color-blackbrown;
  }

  .selectRight {
    float: right;
    margin: -2px 0 0 5px;

    select {
      width: 100px;
      height: 26px;
      font-family: $font-arial;
      font-size: $fontsize-13;
      color: $color-blackbrown;
      border: 1px solid $color-blue-navi-form;
      border-radius: 3px;
      padding: 5px;
      background: $selectarrow_blackIcon_SVG $color-white;
      background-position: center right 3px;
    }
  }
}

.app_headBlk_pad20 {
  padding: 8px 10px 6px 15px;
  height: auto;
  background: $color-blue-tableheadbg;
  border-top: 1px solid $color-white;
  border-left: 1px solid $color-white;
  border-right: 1px solid $color-white;
  border-bottom: 1px solid $color-offwhite-dark;
}

/*Box Bottom Style
------------------------------*/

.appBox_bottom {
  display: block;
  background: $bgTexure;
  border-top: 1px solid $color-offwhite;
  border-bottom: 1px solid $color-white;
  text-align: center;
  font-family: $font-arial;
  font-size: $fontsize-13;
  color: $color-blue;
  height: 34px;
  line-height: 32px;
}

.appBox_bottom a {
  text-decoration: none;
  color: $color-blue;
  font-weight: bold;
}

.appBox_bottom a:hover {
  color: $color-blackbrown-light;
}

/*Box Content Area Style
------------------------------*/

.appContentarea,
.appContentarea_pad20,
.appContentarea_pad20_bgTexure,
.appcontentarea_table_border {
  display: block;
  min-height: 100px;
  background: $color-white;
}

.appContentarea_pad20 {
  padding: 20px;
}

.appContentarea_pad20_bgTexure {
  padding: 20px;
  background-image: $bgTexure;
  border: 1px solid $color-lighterwhite;
}

.appcontentarea_table_border {
  padding: 0px;
  background: $color-white;
  border: 1px solid $color-lighterwhite;
  margin-bottom: 0px;
}

/*Form Style
------------------------------*/

ul.appformColumn,
ul.appformColumn_doctype {
  display: block;
  margin: 0px;
  padding: 0px;

  .appGenericBox {
    display: block;
    padding: 2px 0px 0px 8px;
    height: 40px;
    border: 1px solid $color-blue-lighter;
    border-radius: 3px;
    background: $color-white;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-blackbrown;
  }

  .appformContentHead,
  .appformContentHead_Error {
    display: inline-block;
    vertical-align: top;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-14;
    color: $color-black;
    margin-bottom: 3px;
  }

  .appformContentHead_Error {
    color: $color-alert;
  }

  .appformContentHead_right {
    float: right;

    .noteLink a {
      font-family: $font-arial;
      font-size: $fontsize-12;
      color: $color-blue;
      text-align: right;
      vertical-align: top;
    }
  }

  .appformMandatory {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    height: 3px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-24;
    color: $color-alert;
  }

  .fileUpload {
    display: block;

    .uploadBox {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 82px);
      width: -webkit-calc(100% - 82px);
      padding: 8px 0px 0px 8px;
      height: 40px;
      margin-right: 7px;
      border: 1px solid $color-blue-lighter;
      border-radius: 3px;
      background: $color-white;
      font-family: $font-arial;
      font-size: $fontsize-17;
      color: $color-blackbrown;
    }

    .buttonUploadLayout {
      display: inline-block;
      vertical-align: top;
      width: 75px;

      .buttonUpload {
        width: 75px;
        height: 40px;
        border: 1px solid $color-bluegreen;
        border-radius: 3px;
        font-family: $font-arial;
        font-weight: bold;
        font-size: $fontsize-14;
        color: $color-blackbrown;
        text-align: center;
        outline: none;
        background: $color-blue-tableheadbg;
        background-image: $bgTexure;
      }

      .buttonUpload:hover {
        // background: $color-bluelightgreen;
        cursor: pointer;
      }
    }

    .submitError {
      display: block;
      margin-top: 7px;
      font: $fontsize-12 $font-arial;
      color: $color-white;
      background: $color-error;
      border-radius: 3px;
      padding: 4px 10px;
    }
  }

  .postUploadBtnLabel {
    display: block;
    margin-bottom: 10px;

    .postUploadBut {
      margin: 7px 5px 0px 0px;
      padding: 5px 10px;
      font-family: $font-arial;
      font-size: $fontsize-12;
      color: $color-white;
      background: $color-blue-bg-dark;
      border-radius: 3px;
      outline: none;
      border: 0px;
    }

    .postUploadBut:hover {
      cursor: pointer;
      background: $color-red-light;
    }
  }

  .uploadSuccess,
  .fileFormat {
    float: right;
    margin: -4px 0px 0 0;
    font-family: $font-arial;
    font-size: $fontsize-11;
    color: $color-white;
    background: $color-success;
    border-radius: 3px;
    padding: 3px 8px;
  }

  .fileFormat {
    color: $color-lighterblack;
    background: transparent;
    padding: 4px 0px 0px 0px;
  }

  li.appformColumn_I {
    display: block;
    margin-bottom: 20px;
  }

  li.appformColumn_I:last-child {
    margin-bottom: 5px;
  }

  li.appformColumn_III {
    display: inline-block;
    vertical-align: top;
    width: calc(100% / 3 - 10px);
    width: -webkit-calc(100%/3 - 10px);
    margin-right: 15px;
    margin-bottom: 20px;
  }

  li.appformColumn_III:nth-child(3),
  li.appformColumn_III:nth-child(6),
  li.appformColumn_III:nth-child(9),
  li.appformColumn_III:nth-child(12),
  li.appformColumn_III:nth-child(15),
  li.appformColumn_III:nth-child(18),
  li.appformColumn_III:nth-child(21),
  li.appformColumn_III:nth-child(24),
  li.appformColumn_III:nth-child(27) {
    margin-right: 0px;
  }

  @media ($smaller-than: $breakpoint-699) {
    li.appformColumn_III {
      display: block;
      width: 100%;
    }
  }

  li.appformColumn_inline {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }

  ::-webkit-input-placeholder {
    color: $color-lightblack;
  }

  :-moz-placeholder {
    color: $color-lightblack;
  }

  ::-moz-placeholder {
    color: $color-lightblack;
  }

  :-ms-input-placeholder {
    color: $color-lightblack;
  }

  select:required:invalid {
    color: $color-disable;
  }

  option {
    color: $color-lightblack;
  }

  select::-ms-expand {
    display: none;
  }

  input[type="text"]:focus,
  textarea:focus,
  select:focus {
    border: 1px solid $color-blue;
  }

  select {
    width: 100%;
    font-family: $font-arial;
    font-size: $fontsize-15;
    color: $color-black;
    border: 1px solid $color-blue-lighter;
    border-radius: 3px;
    outline: 0;
    padding: 0px 25px 0px 46px;
    height: 40px;
    background: $selectarrow_blackIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
  }

  input[type="text"],
  textarea {
    display: block;
    font-family: $font-arial;
    font-size: $fontsize-16;
    color: $color-black;
    width: 100%;
    padding: 0px 10px 0px 46px;
    height: 40px;
    border: 1px solid $color-blue-lighter;
    border-radius: 3px;
    background: $color-white;
    outline: 0;
  }

  input[type="file"] {
    width: 95%;
    font-family: $font-arial;
    font-size: $fontsize-13;
    color: $color-lighterblack;
    outline: 0;
  }

  input[type="checkbox"] {
    display: none;
    cursor: pointer;
  }

  input[type="checkbox"]:not([disabled]) + label {
    cursor: pointer;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin: 0 15px 3px 0px;
    border-radius: 3px;
    border: 1px solid $color-blue-lighter;
  }

  input[type="checkbox"]:checked + label:before {
    content: "\2714";
    color: $color-blue;
    font-family: $font-arial;
    font-size: $fontsize-14;
    text-align: center;
    line-height: 16px;
  }

  input[type="radio"] {
    display: none;
    cursor: pointer;
  }

  input[type="radio"]:not([disabled]) + label {
    cursor: pointer;
  }

  input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    margin-top: -3px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid $color-blue-lighter;
  }

  input[type="radio"]:checked + label:before {
    content: "\2022";
    color: $color-blue;
    font-family: $font-arial;
    font-size: $fontsize-24;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }

  .checkRadio_Lable,
  .checkRadio_Lable_approval {
    display: block;
    padding-top: 10px;
    font-family: $font-arial;
    font-size: $fontsize-16;
    color: $color-black;
  }
}

ul.appformColumn_doctype {
  border-bottom: 0px dashed $color-red;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

ul.appformColumn_doctype:last-child {
  border: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media ($smaller-than: $breakpoint-899) {
  ul.appformColumn_doctype {
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
}

/*Form Buttons
------------------------------*/
.appform_btn_label,
.appform_btn_label_notop {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 10px 0px;
  border-top: 4px solid $color-offwhite;

  .buttonLabel_chkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 2px 0px 0px 15px;
    font-size: 16px;
    font-family: $font-arial;
  }

  .appform_btn {
    display: inline-block;
    vertical-align: middle;
    padding: 0px 20px;
    height: 38px;
    margin-right: 5px;
    border: 0px solid $color-blue;
    border-radius: 5px;
    font-family: $font-arial;
    font-weight: bold;
    font-size: $fontsize-16;
    color: $color-white;
    outline: none;
    background: $color-blue;
    text-align: center;
  }

  @media ($smaller-than: $breakpoint-599) {
    .appform_btn {
      padding: 0px 15px;
      height: 32px;
      font-size: $fontsize-14;
    }
  }

  .appform_btn:hover {
    background: $color-blue-navi;
    cursor: pointer;
  }

  .appform_btn[disabled],
  .appform_btn[disabled]:hover,
  .appform_btn.disabled,
  .appform_btn.disabled:hover {
    background: $color-lightblack;
    color: $color-offblack;
    cursor: default;
    text-shadow: none;
    position: static;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0px dotted $color-disable;
  }
}

.appform_btn_label_notop {
  margin: 10px 0px 0px 0px;
  padding: 0px 0px 10px 0px;
  border: none;
}

/*Form Icons
------------------------------*/
.userFormicon,
.personaFormicon,
.entityFormicon,
.mailFormicon,
.linkFormicon,
.jobtitleFormicon,
.cellphFormicon,
.landphFormicon,
.faxFormicon,
.coFormicon,
.locationFormicon,
.numberFormicon,
.dateFormicon,
.typeFormicon,
.internetFormicon,
.documentFormicon,
.amountFormicon,
.searchSettingFormicon,
.genderFormicon {
  position: absolute;
  float: left;
  width: 40px;
  height: 38px;
  border-right: 1px solid $color-blue-lighter;
  margin: 1px 0 0 1px;
  border-radius: 3px 0 0 3px;
}

.userFormicon {
  background: $userFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.personaFormicon {
  // background: $personaFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.entityFormicon {
  // background: $entityFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.mailFormicon {
  background: $mailFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.linkFormicon {
  // background: $linkFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.jobtitleFormicon {
  // background: $jobtitleFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.cellphFormicon {
  background: $mobileFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.landphFormicon {
  background: $landphoneFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.faxFormicon {
  // background: $faxFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.coFormicon {
  // background: $coFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.locationFormicon {
  background: $locationFormIcon_SVG $color-blue-bg-light;
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
}

.numberFormicon {
  background: $numberFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.dateFormicon {
  background: $dateFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.typeFormicon {
  background: $typeFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.internetFormicon {
  // background: $internetFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.documentFormicon {
  background: $documentFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.amountFormicon {
  // background: $loanFormIcon_SVG $color-blue-bg-light;
  background-size: 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.searchSettingFormicon {
  // background: $searchSettingFormIcon_SVG $color-blue-bg-light;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.genderFormicon {
  // background: $genderFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.usericon,
.mailicon,
.phicon,
.noteicon,
.locationicon,
.countryicon,
.form_dollaricon,
.form_dollaricon_declare,
.form_percenticon,
.lockicon,
.matitalicon,
.form_dateicon,
.form_homeicon,
.form_homeicon_declare,
.hastagicon,
.gifticon,
.officeicon,
.typeicon,
.timeicon {
  position: absolute;
  float: left;
  width: 40px;
  height: 38px;
  border-right: 1px solid $color-blue-lighter;
  margin: 1px 0 0 1px;
  border-radius: 3px 0 0 3px;
}

.usericon {
  background: $userFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.mailicon {
  background: $mailFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.phicon {
  background: $phFormIcon_SVG $color-blue-bg-light;
  background-size: 10px;
  background-position: center center;
  background-repeat: no-repeat;
}

.noteicon {
  background: $noteFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.locationicon {
  background: $locationFormIcon_SVG $color-blue-bg-light;
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
}

.countryicon {
  background: $countryFormIcon_SVG $color-blue-bg-light;
  background-size: 15px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_dollaricon {
  background: $dollarFormIcon_SVG $color-blue-bg-light;
  background-size: 10px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_dollaricon_declare {
  height: 50px;
  width: 45px;
  border-right-color: $color-blue-navi-form;
  background: $dollarFormIcon_SVG $color-blue-bg-light;
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_percenticon {
  background: $percentFormIcon_SVG $color-blue-bg-light;
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
}

.lockicon {
  background: $lockFormIcon_SVG $color-blue-bg-light;
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
}

.matitalicon {
  background: $maritalFormIcon_SVG $color-blue-bg-light;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_dateicon {
  background: $dateFormIcon_SVG $color-blue-bg-light;
  background-size: 15px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_homeicon {
  background: $homeFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.form_homeicon_declare {
  height: 50px;
  width: 45px;
  border-right-color: $color-blue-navi-form;
  background: $homeFormIcon_SVG $color-blue-bg-light;
  background-size: 24px;
  background-position: center center;
  background-repeat: no-repeat;
}

.hastagicon {
  background: $numberFormIcon_SVG $color-blue-bg-light;
  background-size: 12px;
  background-position: center center;
  background-repeat: no-repeat;
}

.gifticon {
  background: $giftFormIcon_SVG $color-blue-bg-light;
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
}

.officeicon {
  background: $officeFormIcon_SVG $color-blue-bg-light;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
}

.typeicon {
  background: $typeFormIcon_SVG $color-blue-bg-light;
  background-size: 15px;
  background-position: center center;
  background-repeat: no-repeat;
}

.timeicon {
  background: $timeFormIcon_SVG $color-blue-bg-light;
  background-size: 15px;
  background-position: center center;
  background-repeat: no-repeat;
}

/*Generic Icons
---------------------------*/
.editicon,
.disableicon,
.enableicon,
.deleteicon,
.uploadicon,
.viewicon,
.stakeholdericon {
  float: right;
  width: 16px;
  height: 20px;
  margin: 1px 0px 0px 5px;
}

.editicon {
  background: $editFormIcon_SVG;
  background-size: 17px;
  background-position: center center;
  background-repeat: no-repeat;
}

.disableicon {
  background: $disableFormIcon_SVG;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.enableicon {
  background: $enableFormIcon_SVG;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.deleteicon {
  background: $deleteFormIcon_SVG;
  background-size: 14px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.uploadicon {
  background: $uploadFormIcon_SVG;
  background-size: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.viewicon {
  background: $viewFormIcon_SVG;
  background-size: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.stakeholdericon {
  background: $stakeholderFormIcon_SVG;
  background-size: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-left: 7px;
}

.editStakeholdericon,
.deleteStakeholdericon {
  float: right;
  width: 16px;
  height: 20px;
}

.editStakeholdericon {
  background: $editFormIcon_SVG;
  background-size: 16px;
  background-position: center right;
  background-repeat: no-repeat;
  margin: 7px 0 0 0;
}

.deleteStakeholdericon {
  background: $deleteFormIcon_SVG;
  background-size: 15px;
  background-position: center right;
  background-repeat: no-repeat;
  margin: 1px 0px 0 5px;
}

.pdficon {
  float: right;
  margin: 2px 0px 0px 5px;
  width: 20px;
  height: 20px;
  background: $pdfFormIcon_SVG;
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
}
