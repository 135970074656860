@import "support";

.contactForm,
.contactFormNoShadow {
  display: block;
  padding: 25px;
  background: $color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  .contactForm_Icol {
    display: block;
    margin-bottom: 20px;
  }

  ul.contactForm_IIcol {
    display: block;
    margin: 0px;
    padding: 0px;

    li.contactForm_IIcol {
      display: inline-block;
      vertical-align: top;
      width: calc(100% / 2 - 13px);
      width: -webkit-calc(100%/2 - 13px);
      margin-right: 25px;
      margin-bottom: 25px;
    }

    li.contactForm_IIcol:nth-child(even) {
      margin-right: 0px;
    }

    @media ($smaller-than: $breakpoint-799) {
      li.contactForm_IIcol {
        display: block;
        width: 100%;
        margin-right: 0px;
      }
    }
  }

  .contactFormHead,
  .contactFormHead_Error {
    display: inline-block;
    vertical-align: top;
    font-family: $font-agaramondB;
    font-size: $fontsize-18;
    color: $color-blackbrown;
    margin-bottom: 3px;
  }

  .contactFormHead_Error {
    color: $color-alert;
  }

  .contactFormMandatory {
    display: inline-block;
    vertical-align: top;
    margin-left: 3px;
    height: 3px;
    font-family: $font-agaramondB;
    font-size: $fontsize-30;
    color: $color-alert;
  }

  ::-webkit-input-placeholder {
    color: $color-lightblack;
  }

  :-moz-placeholder {
    color: $color-lightblack;
  }

  ::-moz-placeholder {
    color: $color-lightblack;
  }

  :-ms-input-placeholder {
    color: $color-lightblack;
  }

  select:required:invalid {
    color: $color-disable;
  }

  option {
    color: $color-lightblack;
  }

  select::-ms-expand {
    display: none;
  }

  select {
    width: 100%;
    font-family: $font-agaramond;
    font-size: $fontsize-18;
    color: $color-black;
    border: 1px solid $color-lighterblack;
    border-radius: 3px;
    outline: 0;
    padding: 0px 25px 0px 55px;
    height: 45px;
    background: $selectarrow_blackIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  select:hover {
    cursor: pointer;
    background: $selectarrow_blueIcon_SVG;
    background-position: right 3% center;
    background-color: $color-white;
  }

  input[type="text"],
  textarea {
    display: block;
    font-family: $font-agaramond;
    font-size: $fontsize-18;
    color: $color-black;
    width: 100%;
    padding: 0px 10px 0px 55px;
    height: 45px;
    border: 1px solid $color-lighterblack;
    border-radius: 3px;
    background: $color-white;
    outline: 0;
  }

  textarea {
    padding-top: 10px;
  }

  .contactFormUserIcon,
  .contactFormMailIcon,
  .contactFormPhIcon,
  .contactFormNoteIcon,
  .contactFormHastagIcon,
  .contactFormTypeIcon,
  .contactFormHomeIcon,
  .contactFormLocationIcon {
    position: absolute;
    float: left;
    width: 45px;
    height: 43px;
    border-right: 1px solid $color-lighterblack;
    margin: 1px 0 0 1px;
    border-radius: 3px 0 0 3px;
  }

  .contactFormUserIcon {
    background: $userFormBlackIcon_SVG $color-red-head-bg;
    background-size: 17px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .contactFormMailIcon {
    background: $mailFormBlackIcon_SVG $color-red-head-bg;
    background-size: 17px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .contactFormPhIcon {
    background: $phFormBlackIcon_SVG $color-red-head-bg;
    background-size: 10px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .contactFormNoteIcon {
    background: $noteFormBlackIcon_SVG $color-red-head-bg;
    background-size: 17px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .contactFormHastagIcon {
    background: $numberFormBlackIcon_SVG $color-red-head-bg;
    background-size: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .contactFormTypeIcon {
    background: $typeFormBlackIcon_SVG $color-red-head-bg;
    background-size: 16px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .contactFormHomeIcon {
    background: $homeFormBlackIcon_SVG $color-red-head-bg;
    background-size: 17px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .contactFormLocationIcon {
    background: $locationFormBlackIcon_SVG $color-red-head-bg;
    background-size: 14px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .contactForm_btn_label {
    display: block;
    margin-bottom: 15px;

    .contactFormBtn {
      display: inline-block;
      vertical-align: middle;
      padding: 0px 29px;
      height: 45px;
      margin-right: 8px;
      border: 0px solid $color-red;
      border-radius: 5px;
      font-family: $font-agaramondB;
      font-size: $fontsize-20;
      color: $color-white;
      outline: none;
      background: $color-red;
      text-align: center;
    }

    .contactFormBtn:hover {
      background: $color-blue-navi;
      cursor: pointer;
    }

    .contactFormBtn[disabled],
    .contactFormBtn[disabled]:hover,
    .contactFormBtn.disabled,
    .contactFormBtn.disabled:hover {
      background: $color-lightblack;
      color: $color-offblack;
      cursor: default;
      text-shadow: none;
      position: static;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 0px dotted $color-disable;
    }
  }
}

.contactFormNoShadow {
  box-shadow: none;
  background: transparent;
  padding: 5px 20px 20px 18px;
}
