@import "support";

.dashboard_pgBlk {
    display: block;
    margin-top: 25px;

    /*Refresh Icon*/
    .blockRefresh_dash {
        float: right;
        height: 25px;
        aspect-ratio: 1/1;
        border-radius: 3px;
        background: $refreshIcon_SVG $color-blue;
        background-size: 12px;
        background-position: center center;
        margin: -1px 0 0 5px;
    }

    .blockRefresh_dash:hover {
        cursor: pointer;
        background-color: $color-blue-dark;
    }

    /* Application Status
    -------------------------------*/

    .selectAppNo {
        display: inline-block;
        vertical-align: top;
        margin: 0px 0 0 5px;
    }

    ul.statusSummary {
        display: block;
        border: 1px solid $color-white;
        border-bottom: 0.5px solid $color-offwhite;
        margin-bottom: 18px;
        padding: 0 0px;
        background: $color-blue-form-bg;

        li.statusSummary {
            display: inline-block;
            vertical-align: top;
            font-family: $font-arial;
            font-size: $fontsize-14;
            color: $color-blackbrown;
            height: 30px;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        li.statusSummary:nth-child(1) {
            width: 120px;
            font-weight: bold;
            color: $color-black;
            padding-left: 5px;
        }

        li.statusSummary:nth-child(2) {
            width: 160px;
            margin-right: 5px;
        }

        li.statusSummary:nth-child(3) {
            width: 65px;
            font-weight: bold;
        }

        li.statusSummary:nth-child(4) {
            width: calc(100% - 590px);
            width: -webkit-calc(100% - 590px);
            margin-right: 5px;
        }

        li.statusSummary:nth-child(5) {
            width: 105px;
            font-weight: bold;
            color: $color-black;
        }

        li.statusSummary:nth-child(6) {
            width: 130px;
        }

        @media ($smaller-than: $breakpoint-899) {
            li.statusSummary:nth-child(2) {
                width: calc(100% - 120px);
            }

            li.statusSummary:nth-child(4) {
                width: calc(100% - 100px);
            }
        }
    }

    ul.loanStatus {
        display: block;
        margin: 0 0 10px 0;
        background: -webkit-linear-gradient(to bottom, $color-white 50%, $color-blackbrown-light 30%, $color-blackbrown-light 58%, $color-white 58%);
        background: -moz-linear-gradient(to bottom, $color-white 50%, $color-blackbrown-light 30%, $color-blackbrown-light 58%, $color-white 58%);
        background: linear-gradient(to bottom, $color-white 50%, $color-blackbrown-light 30%, $color-blackbrown-light 58%, $color-white 58%);

        li.loanStatus {
            display: inline;
            margin-right: 15.30px;
        }

        li.loanStatus:last-child {
            margin-right: 0px;
        }

        li.loanStatus {
            display: inline-block;
            font-family: $font-arial;
            font-weight: bold;
            font-size: $fontsize-14;
            color: $color-white;
            text-decoration: none;
            height: 37px;
            line-height: 37px;
            padding: 0px 20.2px 0px 0px;
            border-radius: 6px;
            background: $color-blue-navi-form;
            transition-property: color;
            transition-duration: 0.5s;
            -webkit-transition: 0s;
            transition: 0.5s;
            transition-delay: 0.1s;
        }

        li.loanStatus.current {
            position: relative;
            color: $color-white;
            background: $color-blackbrown-light;
            background-image: $bgTexure;
        }

        li.loanStatus.success {
            position: relative;
            color: $color-white;
            background: $color-success-light;
            background-image: $bgTexure;
        }

        .statusTxt {
            display: inline-block;
        }

        .statusnumber {
            display: inline-block;
            vertical-align: top;
            font-family: $font-arial;
            font-weight: bold;
            font-size: $fontsize-16;
            color: $color-white;
            text-align: center;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            background: $color-blue-logo;
            margin-right: 10px;
            width: 35px;
            height: 37px;
            line-height: 37px;
        }

        .statusnumber.active {
            background: $color-blackbrown;
            border-right: .5px solid $color-offwhite;
        }

        .statusnumber.successNo {
            background: $color-success;
        }

        @media ($smaller-than: $breakpoint-1200) {
            .statusnumber {
                display: none;
            }

            li.loanStatus {
                width: calc(100%/8 - 5px);
                width: -webkit-calc(100%/8 - 5px);
                padding: 0px;
                margin-right: 5px;
                text-align: center;
            }

            li.loanStatus:last-child {
                margin-right: 0px;
            }
        }

        @media ($smaller-than: $breakpoint-899) {
            li.loanStatus {
                margin: 0 10px 10px 0;
                width: calc(100%/4 - 8px);
                width: -webkit-calc(100%/4 - 8px);
            }

            li.loanStatus:nth-child(4) {
                margin-right: 0px;
            }
        }

        @media ($smaller-than: $breakpoint-599) {
            li.loanStatus {
                width: calc(100%/3 - 7px);
                width: -webkit-calc(100%/3 - 7px);
            }

            li.loanStatus:nth-child(4) {
                margin-right: 10px;
            }

            li.loanStatus:nth-child(3),
            li.loanStatus:nth-child(6) {
                margin-right: 0px;
            }

            li.loanStatus:nth-child(7),
            li.loanStatus:nth-child(8) {
                margin-bottom: 0px;
            }
        }
    }

    @media (max-width: 1199px) {
        ul.loanStatus {
            background: none;
        }
    }

    /*Requests
    -------------------------------*/
    .dash_request_blk {
        display: block;

        .dash_request_head,
        .dash_request {
            table {
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;
                margin: 0px;
                padding: 0px;
                border-top: 1px solid $color-white;
                border-bottom: 1px solid $color-offwhite;
                background-image: $bgTexure;

                td {
                    font-family: $font-arial;
                    font-weight: bold;
                    font-size: $fontsize-14;
                    color: $color-black;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 5px;
                }

                td:first-child {
                    width: 30px;
                    padding-left: 17px;
                    white-space: nowrap;
                }

                td:nth-child(2) {
                    width: 135px;
                    white-space: nowrap;
                }

                td:nth-child(3) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 1px;
                }

                td:nth-child(4) {
                    width: 100px;
                    white-space: nowrap;
                }

                td:nth-child(5) {
                    width: 50px;
                    white-space: nowrap;
                }
            }
        }

        .dash_request {
            overflow-y: scroll;
            min-height: 108px;

            table {
                background-image: none;

                td {
                    vertical-align: top;
                    font-weight: normal;
                    color: $color-blackbrown;
                    line-height: 35px;
                    height: 35px;
                    border-bottom: 1px solid $color-offwhite;
                }

                td:nth-child(5) {
                    width: 35px;
                    text-align: right;
                }

                /*15px less than head table*/

                td a {
                    color: $color-blue;
                    text-decoration: none;
                }

                td a:hover {
                    color: $color-blackbrown-light;
                }

                tr:last-child td {
                    border-bottom: 0px;
                }
            }
        }

        @-moz-document url-prefix() {
            .dash_request {
                height: 109px;
            }
        }

        .dash_uploadIcon,
        .dash_uploadIcon_dis {
            float: right;
            height: 20px;
            width: 20px;
            border-radius: 3px;
            background: $upload_whiteIcon_SVG $color-blue-navi-form;
            background-size: 9px;
            background-position: center center;
            margin-top: 7px;
        }

        .dash_uploadIcon_dis {
            background: $upload_whiteIcon_SVG $color-offblack;
            background-size: 9px;
            background-position: center center;
        }

        .dash_uploadIcon:hover {
            background-color: $color-blue-dark;
            cursor: pointer;
        }

        .dash_unread,
        .dash_read {
            height: 7px;
            width: 7px;
            background: $color-blue;
            border-radius: 50px;
            margin-top: 13px;
        }

        .dash_read {
            background: $color-offblack;
        }

    }

    /*Notifications
    -------------------------------*/
    .dash_notify_blk {
        display: block;

        .dash_notify_head,
        .dash_notify {
            display: block;

            ::-webkit-scrollbar {
                width: 12px;
            }

            ::-webkit-scrollbar-track {
                background: $color-blue;
            }

            ::-webkit-scrollbar-thumb {
                background: $color-blue;
            }

            table {
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;
                margin: 0px;
                padding: 0px;
                border: 1px solid $color-white;
                background-image: $bgTexure;

                td:nth-child(1) {
                    padding-left: 17px;
                }

                td:nth-child(2) {
                    width: 140px;
                }

                td:last-child {
                    width: 95px;
                    padding-right: 17px;
                }

                td {
                    border-bottom: 1px solid $color-offwhite;
                    font-family: $font-arial;
                    font-weight: bold;
                    font-size: $fontsize-14;
                    color: $color-black;
                    height: 32px;
                    line-height: 32px;
                }
            }
        }

        .dash_notify {
            overflow-y: scroll;
            min-height: 108px;

            table {
                border-left: none;
                border-right: none;
                background-image: none;
                border-bottom: 1px solid $color-offwhite;

                td:last-child {
                    width: 80px;
                }

                /*15px less than head table*/

                td {
                    vertical-align: top;
                    font-weight: normal;
                    line-height: 35px;
                    height: 35px;
                }

                td a {
                    color: $color-blue;
                    text-decoration: none;
                }

                td a:hover {
                    color: $color-blackbrown;
                }

                tr:last-child td {
                    border-bottom: 0px;
                }
            }
        }

        @-moz-document url-prefix() {
            .dash_notify {
                height: 123px;
            }
        }
    }

    /*Pre-Approval
    -------------------------------*/
    ul.dash_preapprove_IIIcol {
        display: block;
        margin: 15px;

        li.dash_preapprove_IIIcol {
            display: inline-block;
            vertical-align: top;
            width: calc(100%/3 - 8px);
            width: -webkit-calc(100%/3 - 8px);
            background-image: $bgTexure;
            margin: 0px 12px 0px 0px;
            padding: 12px;
            border: 1px solid $color-offwhite;
            border-radius: 4px;
            margin-bottom: 15px;
        }

        li.dash_preapprove_IIIcol:last-child {
            margin-right: 0px;
        }

        @media ($smaller-than: $breakpoint-599) {
            li.dash_preapprove_IIIcol {
                display: block;
                width: 100%;
                margin: 0 0 15px 0;
            }
        }

        .dash_preapprove_head {
            display: block;
            font-family: $font-arial;
            font-weight: bold;
            font-size: $fontsize-14;
            color: $color-blackbrown;
            padding-bottom: 3px;
            border-bottom: 0px solid $color-blue-form-bg-dark;
        }

        .dash_preapprove_head_separator {
            display: block;
            width: 80px;
            height: 4px;
            border-radius: 25px;
            margin-bottom: 15px;
            background: -webkit-linear-gradient(to right, $color-blackbrown-light 30px, $color-blue-navi-form 0px);
            background: -moz-linear-gradient(to right, $color-blackbrown-light 30px, $color-blue-navi-form 0px);
            background: linear-gradient(to right, $color-blackbrown-light 30px, $color-blue-navi-form 0px);
        }

        .dash_preapproveText {
            display: block;
            font-family: $font-arial;
            font-size: $fontsize-13;
            color: $color-blackbrown-light;
        }

        .dash_preapprove_btnLabel {
            display: block;
            margin: 20px 0 2px 0;

            .dash_preapprove_btn {
                display: inline-block;
                vertical-align: middle;
                padding: 0px 12px;
                height: 30px;
                margin-right: 5px;
                border: 0px solid $color-blue;
                border-radius: 5px;
                font-family: $font-arial;
                font-weight: bold;
                font-size: $fontsize-13;
                color: $color-white;
                outline: none;
                background: $color-blue-navi-form;
                text-align: center;
            }

            .dash_preapprove_btn:hover {
                background: $color-blue-navi;
                cursor: pointer;
            }

            .dash_preapprove_btn[disabled],
            .dash_preapprove_btn[disabled]:hover,
            .dash_preapprove_btn.disabled,
            .dash_preapprove_btn.disabled:hover {
                background: $color-lightblack;
                color: $color-offblack;
                cursor: default;
                text-shadow: none;
                position: static;
                -moz-box-shadow: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                border: 0px dotted $color-disable;
            }
        }
    }

    @media ($smaller-than: $breakpoint-599) {
        ul.dash_preapprove_IIIcol {
            padding-bottom: 5px;
        }
    }


    /*Form 1003
    -------------------------------*/
    .dash_form1003 {
        display: block;

        .dash_form1003_head,
        .dash_form1003 {
            table {
                border-collapse: collapse;
                border-spacing: 0;
                width: 100%;
                margin: 0px;
                padding: 0px;
                border-top: 1px solid $color-white;
                border-bottom: 1px solid $color-offwhite;
                background-image: $bgTexure;

                td {
                    font-family: $font-arial;
                    font-weight: bold;
                    font-size: $fontsize-14;
                    color: $color-black;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 5px;
                }

                td:first-child {
                    width: 130px;
                    padding-left: 17px;
                    white-space: nowrap;
                }

                td:nth-child(2) {
                    width: calc(100%/5 - 0px);
                    width: -webkit-calc(100%/5 - 0px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 1px;
                }

                td:nth-child(3) {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 1px;
                }

                td:nth-child(4) {
                    width: 80px;
                    white-space: nowrap;
                }

                td:nth-child(5) {
                    width: 50px;
                    white-space: nowrap;
                }
            }
        }

        .dash_form1003 {
            overflow-y: scroll;
            height: 144px;

            table {
                background-image: none;

                td {
                    vertical-align: top;
                    font-weight: normal;
                    font-size: $fontsize-14;
                    color: $color-blackbrown;
                    line-height: 35px;
                    height: 35px;
                    border-bottom: 1px solid $color-offwhite;
                }

                td:nth-child(5) {
                    width: 35px;
                    text-align: right;
                }

                /*15px less than head table*/

                td a {
                    color: $color-blue;
                    text-decoration: none;
                }

                td a:hover {
                    color: $color-blackbrown-light;
                }

                tr:last-child td {
                    border-bottom: 0px;
                }
            }
        }

        @-moz-document url-prefix() {
            .dash_form1003 {
                height: 145px;
            }
        }

        /*	.resumeAppIcon,
    .downloadAppIcon {
	  display: inline-block;
	  height: 20px;
	  width: 20px;
	  background: $resumeFormIcon_SVG;
	  background-position: bottom right;
	  background-size: 18px;
	  margin: 5px 0 0 0;
    }
    .downloadAppIcon {
	  background: $downloadIcon_SVG;
    }*/

        .dash_downloadForm,
        .dash_resumeForm {
            float: right;
            height: 20px;
            width: 20px;
            border-radius: 3px;
            background: $download_whiteIcon_SVG $color-blue-navi-form;
            background-size: 9px;
            background-position: center center;
            margin-top: 7px;
        }

        .dash_resumeForm {
            background: $resume_whiteIcon_SVG $color-blue-navi-form;
            background-size: 10px;
            background-position: center center;
        }

        .dash_downloadForm:hover,
        .dash_resumeForm:hover {
            background-color: $color-black;
            cursor: pointer;
        }
    }

    @media ($smaller-than: $breakpoint-599) {}
}