.gallery {
  width: 100%;
}

.gallery-container {
  align-items: center;
  display: flex;
  height: 350px;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.gallery-item {
  height: 150px;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 250px;
  z-index: 0;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .35);
}

.gallery-item-1 {
  left: 15%;
  opacity: .4;
  transform: translateX(-50%);
}

.gallery-item-2,
.gallery-item-4 {
  height: 250px;
  opacity: 1;
  width: 350px;
  z-index: 1;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .35);
}

.gallery-item-2 {
  left: 30%;
  transform: translateX(-50%);
}

.gallery-item-3 {
  /*  box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);*/
  height: 350px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  z-index: 2;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .35);
}

.gallery-item-4 {
  left: 70%;
  transform: translateX(-50%);
}

.gallery-item-5 {
  left: 85%;
  opacity: .4;
  transform: translateX(-50%);
}

.gallery-controls {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.gallery-controls button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  margin: 0 20px;
  padding: 0 12px;
  text-transform: capitalize;
  color: black;
}

.gallery-controls button:focus {
  outline: none;
}

.gallery-controls-previous {
  position: relative;
}

.gallery-controls-previous::before {
  /*  border: solid #000;*/
  /*  border-width: 0 2px 2px 0;*/
  content: '\0027ea';
  display: inline-block;
  height: 4px;
  left: -5px;
  /*  left: -10px;*/
  /*  padding: 2px;*/
  position: absolute;
  top: 0;
  /*  transform: rotate(135deg) translateY(-50%);*/
  /*  transition: left 0.15s ease-in-out;*/
  width: 4px;
  color: red;
}

.gallery-controls-previous:hover::before {
  left: -18px;
}

.gallery-controls-next {
  position: relative;
}

.gallery-controls-next::before {
  /*  border: solid #000;*/
  /*  border-width: 0 2px 2px 0;*/
  content: '\0027eb';
  display: inline-block;
  height: 4px;
  /*  padding: 2px;*/
  position: absolute;
  right: -5px;
  /*  right: -10px;*/
  /*  top: 50%;*/
  /*  transform: rotate(-45deg) translateY(-50%);*/
  /*  transition: right 0.15s ease-in-out;*/
  width: 4px;
  color: red;
}

.gallery-controls-next:hover::before {
  right: -18px;
}

.gallery-nav {
  bottom: -15px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
}

.gallery-nav li {
  background: #ccc;
  border-radius: 50%;
  height: 10px;
  margin: 0 16px;
  width: 10px;
}

.gallery-nav li.gallery-item-selected {
  background: #555;
}