@import "support";

/*Footer Common*/
#footer {
  clear: both;
  width: 100%;
  position: relative;
  z-index: 10;
  height: 55px;
  margin-top: -55px;
  background: $color-white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  .footer_blk {
    display: block;

    ul.footerLinks {
      display: block;
      text-align: center;

      li.footerLinks {
        display: inline-block;
        vertical-align: top;
        font-family: $font-agaramondB;
        font-size: $fontsize-15;
        color: $color-blackblue;
        padding: 10px 0px 3px 0px;
      }

      li.footerLinks::after {
        content: "\00007c";
        padding: 0px 10px;
        color: $color-lightblack;
      }

      li.footerLinks:last-child::after {
        content: "";
      }

      li.footerLinks a {
        color: $color-black;
      }

      li.footerLinks a:hover {
        color: $color-red;
        cursor: pointer;
      }

      @media ($smaller-than: $breakpoint-599) {
        // li.footerLinks:nth-child(2),
        li.footerLinks:last-child,
        li.footerLinks:nth-last-child(2) {
          display: none;
        }

        li.footerLinks:nth-child(2):after {
          content: "";
        }
      }

      @media ($smaller-than: $breakpoint-599) {
        li.footerLinks a {
          color: $color-lightBlueFooterText;
        }
      }
    }

    .footer_copyright {
      display: block;
      font: $fontsize-13 $font-agaramond;
      color: $color-blackbrown-light;
      text-align: center;
    }

    @media ($smaller-than: $breakpoint-599) {
      .footer_copyright {
        color: $color-lightBlueFooterText;
      }
    }
  }
}

@media ($smaller-than: $breakpoint-599) {
  #footer {
    background: $color-blue-darker;
    background-image: $bgTexure;
  }
}

//Footer Home
#footer_home {
  background: $color-blue-darker;
  background-image: $bgTexure;
  padding: 50px 0px 30px 0px;

  ul.footerIIIcol {
    display: block;

    .footerHeadText {
      display: block;
      font-family: $font-agaramondB;
      font-size: $fontsize-24;
      color: $color-lightBlueFooterText;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    li.footerIIIcol {
      display: inline-block;
      vertical-align: top;
      margin-right: 50px;
    }

    li.footerIIIcol:first-child {
      width: calc(100% - 750px);
      width: -webkit-calc(100% - 750px);
    }

    li.footerIIIcol:nth-child(2) {
      width: 300px;
    }

    li.footerIIIcol:last-child {
      width: 350px;
      margin-right: 0px;
    }

    ul.footerLogoSocial {
      display: block;
      margin-bottom: 65px;

      li.footerLogoSocial {
        display: inline-block;
        vertical-align: top;
        width: calc(120% / 2);
        width: -webkit-calc(120% / 2);
      }

      li.footerLogoSocial:last-child {
        width: calc(80% / 2);
        width: -webkit-calc(80% / 2);
      }

      .footerLogoSetting {
        height: 50px;
        width: 100%;
        margin-top: 5px;
        background-position: left top;
        background-size: 200px;
        opacity: 0.8;
        margin-bottom: 10px;
      }

      .footerNMLStxt {
        display: inline-block;
        vertical-align: top;
        font-family: $font-agaramondB;
        font-size: $fontsize-18;
        color: $color-lightBlueFooterText;
        text-transform: uppercase;
        margin-right: 5px;
      }

      ul.socialmedia {
        display: block;
        margin: 0px 0px 22px 0px;
        padding: 0px;

        li.socialmedia {
          display: inline-block;
          vertical-align: top;
          margin-right: 7px;
        }

        .icon_fb,
        .icon_twitter,
        .icon_linkedin,
        .icon_youtube,
        .icon_insta {
          display: block;
          height: 32px;
          width: 32px;
          opacity: 0.6;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
        }

        .icon_fb:hover,
        .icon_twitter:hover,
        .icon_linkedin:hover,
        .icon_youtube:hover,
        .icon_insta:hover {
          opacity: 0.9;
          cursor: pointer;
        }

        .icon_fb {
          background: $fbIcon_SVG;
          background-size: 32px;
          background-position: top left;
        }

        .icon_twitter {
          background: $twitterIcon_SVG;
          background-size: 32px;
          background-position: top left;
        }

        .icon_linkedin {
          background: $linkedinIcon_SVG;
          background-size: 32px;
          background-position: top left;
        }

        .icon_youtube {
          background: $youtubeIcon_SVG;
          background-size: 32px;
          background-position: top left;
        }

        .icon_insta {
          background: $instaIcon_SVG;
          background-size: 32px;
          background-position: top left;
        }
      }
    }

    .footerCopyright {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-18;
      color: $color-lightBlueFooterText;
      margin-bottom: 5px;
    }

    .footerTerms {
      display: block;
      text-decoration: none;
      font-size: $fontsize-18;
      color: $color-lightBlueFooterText;
    }

    @media ($smaller-than: $breakpoint-1200) {
      li.footerIIIcol,
      li.footerIIIcol:first-child {
        display: block;
        width: 100%;
        text-align: center;
        margin: 0px;
      }

      li.footerIIIcol:nth-child(2),
      li.footerIIIcol:last-child,
      ul.footerLogoSocial {
        display: none;
      }

      .footerCopyright {
        font-size: $fontsize-15;
        margin-bottom: 3px;
      }

      .footerTerms {
        font-size: $fontsize-14;
      }
    }

    .footerTerms .termsLink {
      font-family: $font-agaramond;
      font-size: $fontsize-17;
      color: $color-lightBlueFooterText;
      opacity: 0.7;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }

    .footerTerms .termsLink:hover {
      opacity: 1;
    }

    ul.footerQuickLink {
      display: block;

      li.footerQuickLink {
        display: block;
        margin-bottom: 15px;
      }

      li.footerQuickLink .quickLink {
        font-family: $font-agaramond;
        font-size: $fontsize-18;
        color: $color-lightBlueFooterText;
        opacity: 0.7;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }

      li.footerQuickLink .quickLink:hover {
        opacity: 1;
      }
    }

    .footerGetUpdate {
      display: block;
      font-family: $font-agaramond;
      font-size: $fontsize-18;
      color: $color-lightBlueFooterText;
      margin-bottom: 20px;
    }

    ::-webkit-input-placeholder {
      color: $color-lightBlueFooterText;
    }

    :-moz-placeholder {
      color: $color-lightBlueFooterText;
    }

    ::-moz-placeholder {
      color: $color-lightBlueFooterText;
    }

    :-ms-input-placeholder {
      color: $color-lightBlueFooterText;
    }

    input[type="email"] {
      height: 40px;
      width: 100%;
      padding: 0px;
      border: none;
      border-bottom: 1px solid $color-blueFooter;
      font-family: $font-agaramondB;
      font-size: $fontsize-26;
      background: transparent;
      color: $color-white;
      outline: 0;
      margin-bottom: 10px;
    }

    .footer_button {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 45px;
      font-family: $font-agaramondB;
      font-size: $fontsize-26;
      color: $color-white;
      outline: none;
      background: $color-blueFooter;
      text-align: center;
      transition-property: background;
      transition-duration: 0.5s;
    }

    .footer_button:hover {
      background: $color-lightBlueFooterText;
      color: $color-blueFooter;
      cursor: pointer;
    }
  }
}

@media ($smaller-than: $breakpoint-1200) {
  #footer_home {
    padding: 15px 0px 0px 0px;
  }
}

@media ($smaller-than: $breakpoint-899) {
  #footer_home {
    height: 60px;
  }
}

@media ($smaller-than: $breakpoint-699) {
  #footer_home {
    height: 65px;
  }
}
