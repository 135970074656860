@import "support";

.i3Logo_fintech {
  background: $i3logo_fintech_SVG;
}

.i3LendingLogo {
  background: $i3lending_logo_SVG;
}

.i3LendingWhiteLogo {
  background: $i3lendingWhite_logo_SVG;
}

.i3LogoIcon {
  background: $i3LogoIcon_SVG;
}

/*General Responsiveness*/
@media ($bigger-than: $breakpoint-1200) {
  .widthfix {
    width: $width-1200px;
    margin: 0px auto;
  }

  .widthfix_10px {
    width: $width-1200px;
    margin: 0px auto;
  }
}

@media ($smaller-than: $breakpoint-1200) {
  .widthfix {
    width: auto;
    margin: 0px 0px;
  }

  .widthfix_10px {
    width: auto;
    margin: 0px 10px;
  }
}

/*No Content*/
.noContent {
  display: block;
  padding: 20px;
  font-family: $font-arial;
  font-size: $fontsize-14;
  color: $color-lightblack;
  text-align: center;
}

/*Refresh Icon*/
.blockRefresh {
  float: right;
  height: 25px;
  aspect-ratio: 1/1;
  border-radius: 3px;
  background: $refreshIcon_SVG $color-blue;
  background-size: 12px;
  background-position: center center;
  margin: 0px 0 0 5px;
}

.blockRefresh:hover {
  cursor: pointer;
  background-color: $color-blue-dark;
}

/*Disable Background*/
.disable_bg,
.pop_disable_bg {
  background: $color-black;
  opacity: 0.3;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2450;
}

.pop_disable_bg {
  display: none;
  background: transparent;
  opacity: 1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpiZGBgOMNABGBiIBKMKqSOQoAAAwBVNQDgpAAyvAAAAABJRU5ErkJggg==");
  z-index: 2510;
}

/*Spacer for Form*/
.spacer_5margin_bottom {
  display: block;
  margin-bottom: 5px;
}

.spacer_8margin_bottom {
  display: block;
  margin-bottom: 8px;
}

.spacer_10margin_bottom {
  display: block;
  margin-bottom: 10px;
}

.spacer_15margin_bottom {
  display: block;
  margin-bottom: 15px;
}

.spacer_20margin_bottom {
  display: block;
  margin-bottom: 20px;
}

.spacer_25margin_bottom {
  display: block;
  margin-bottom: 25px;
}

.spacer-10margin_bottom {
  display: block;
  margin-bottom: -10px;
}

.vblk_nowidth {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}

.vblk_nowidth_nomargin {
  display: inline-block;
  vertical-align: top;
}

.spacer_left_20px {
  display: block;
  margin-left: 20px;
}

/*Width label for Form*/
.width_25per,
.width_50per,
.width_75per,
.width_85per {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.width_25per {
  width: 25%;
}

.width_75per {
  width: 75%;
}

.width_85per {
  width: 85%;
}

/*Page Top Scroll
----------------------------------*/
#scroll-top {
  position: fixed;
  z-index: 9000;
  /* On top of everything */
  bottom: 0;
  right: 0;
  display: inline-block;
  padding: 0.6em 0.5em 0.6em 0.9em;
  border-top-left-radius: 3em;
  color: $color-white;
  text-decoration: none;
  border-left: 1px solid $color-blackblue;
  border-top: 1px solid $color-blackblue;
  background: $color-blue-navi;
  background-image: $bgTexure;
}

/* Up Arrow for the scroll top link */
#scroll-top:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  border-bottom: 0.5em solid $color-white;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.loader-modal {
  position: fixed;
  background: transparent;
  color: $color-success;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.errormessage,
.errormessage_center,
.successmessage {
  display: block;
  border-radius: 5px;
  padding-left: 10px;
  font-family: $font-arial;
  font-size: $fontsize-16;
  color: $color-white;
  background: $color-error;
  height: 45px;
  line-height: 45px;
  margin-bottom: 10px;
}

.errormessage_center {
  text-align: center;
  margin: 15px auto;
  width: 90%;
}

.successmessage {
  background: $color-success;
}

@media (max-width: 699px) {
  .errormessage,
  .successmessage {
    height: 35px;
    line-height: 35px;
    font-size: $fontsize-16;
  }
}

.arrowOpen {
  cursor: pointer;
  background: $selectarrow_redIcon_SVG;
  background-position: right 3% center;
  background-color: $color-white;
  background-size: 20px;
}
